import { OptionItems } from 'app/models';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (str: any) => void;
  isClearable?: boolean;
  disabled?: boolean;
  isInModal?: boolean;
}

const MultiSelectField = (props: OtherProps & FieldHookConfig<any>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    onChange,
    isClearable,
    disabled,
    isInModal = false,
  } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        isMulti={true}
        defaultValue={field.value}
        placeholder={placeholder}
        options={options}
        className={'basic-multi-select'}
        {...field}
        onChange={e => {
          const value = e;
          if (e) {
            helpers.setValue(value);
          } else {
            helpers.setValue('');
          }
          if (onChange) {
            onChange(value);
          }
        }}
        value={field.value}
        isClearable={isClearable}
        isDisabled={disabled}
        createOptionPosition="first"
        menuPortalTarget={!isInModal ? document.body : undefined}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default MultiSelectField;
