import { FileResponse } from 'app/models';
import Axios from './index';

/**
* * Upload and resize image file (https://jcabc-app-dev.appicidea.com/doc/#/File/FilesController_uploadImage)
* @parama file string ($binary)
+*/

export const uploadImage = async (fd: any, config?: any) => {
  const { data } = await Axios.post<FileResponse>('/files/image', fd, {
    ...config,
    // 60s for image
    timeout: 300000,
  });
  return data;
};

/**
* * Upload pdf file (https://jcabc-app-dev.appicidea.com/doc/#/File/FilesController_uploadFile)
* @parama file string ($binary)
+*/

export const uploadFile = async (fd: any, config?: any) => {
  const { data } = await Axios.post<FileResponse>('/files/raw', fd, {
    ...config,
    // 60s for image
    timeout: 300000,
  });
  return data;
};
