import { JobStatusMap } from 'app/constants';
import { getImageURL } from 'app/helpers/CommonHelper';
import { EmploymentType, Job, PayrollPeriod } from 'app/models';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, UncontrolledTooltip } from 'reactstrap';

const JobColumns = () => [
  {
    text: '',
    dataField: 'img',
    formatter: (job: Job) => (
      <>
        {job?.Company?.logo ? (
          <img
            className="avatar-lg rounded-circle "
            src={getImageURL(job.Company.logo)}
            alt="cover"
            style={{ objectFit: 'contain' }}
          />
        ) : (
          <div className="avatar-lg">
            <h1 className="w-100 h-100 avatar-videoUrl rounded-circle bg-light text-secondary d-flex flex-row justify-content-center align-items-center">
              <i className="bx bx-briefcase-alt" />
            </h1>
          </div>
        )}
      </>
    ),
  },
  {
    text: '編號',
    dataField: 'jobID',
    canSort: true,
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{job.jobId ? `#${job.jobId}` : '-'}</div>
        </h5>
      </>
    ),
  },
  {
    text: '公司或機構名稱',
    dataField: 'comapnayName',
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            <Link to={`/companies/detail/${job.companyId}`}>
              {job.Company.name}
            </Link>
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '職位',
    dataField: 'jobTitle',
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{job.industry || '-'}</div>
          <div className="text-dark">{job.title}</div>
          <div className="text-muted font-size-12">{job.address}</div>
        </h5>
      </>
    ),
  },
  {
    text: '工作詳情',
    dataField: 'description',
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{job.description}</div>
        </h5>
      </>
    ),
  },
  {
    text: '薪金',
    dataField: 'salary',
    canSort: true,
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {job.employmentType ? EmploymentType[job.employmentType] : '-'}
          </div>
          <div className="text-dark">
            {`${job.salary} [${
              job.payrollPeriod ? PayrollPeriod[job.payrollPeriod] : '-'
            }]`}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '刊登日期',
    dataField: 'postedAt',
    canSort: true,
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(job.postedAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '結束招聘日期',
    dataField: 'endedAt',
    canSort: true,
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(job.endedAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '狀態',
    dataField: 'status',
    canSort: true,
    formatter: (job: Job) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">
            {job?.status ? (
              <Badge
                pill
                className={`badge-soft-${
                  JobStatusMap.get(job.status)?.colorType
                } ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
              >
                {JobStatusMap.get(job.status)?.label}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (job: Job) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <Link
            to={`/jobs/items/detail/${job.jobId}`}
            id={'detail' + job.jobId}
          >
            <i className="bx bx-detail" />
            <UncontrolledTooltip placement="top" target={'detail' + job.jobId}>
              檢視工作機會
            </UncontrolledTooltip>
          </Link>
        </li>
        <li className="list-inline-item px-2">
          <Link to={`/jobs/items/edit/${job.jobId}`} id={'edit' + job.jobId}>
            <i className="bx bx-edit-alt" />
            <UncontrolledTooltip placement="top" target={'edit' + job.jobId}>
              編輯工作機會
            </UncontrolledTooltip>
          </Link>
        </li>
        <li className="list-inline-item px-2">
          <Link
            to={`/jobs/items/new?referenceId=${job.jobId}`}
            id={'new' + job.jobId}
          >
            <i className="bx bx-plus-circle" />
            <UncontrolledTooltip placement="top" target={'new' + job.jobId}>
              複製工作機會並新增
            </UncontrolledTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default JobColumns;
