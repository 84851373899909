import Axios from '.';

/**
 * Get Region list in Chinese (https://jcabc-app-dev.appicidea.com/doc/#/Setting/SettingController_getChineseRegion)
 */

export interface GetRegionListParams {
  lang: 'zh-HK' | 'en';
}

export const getRegionList = async (params: GetRegionListParams) => {
  const { data } = await Axios.get<any>('/settings/region', { params });
  return data;
};

/**
 * Get Region list in Chinese (https://jcabc-app-dev.appicidea.com/doc/#/Setting/SettingController_getChineseDistrict)
 */
export interface GetDistrictListParams {
  lang: 'zh-HK' | 'en';
  region: string;
}

export const getDistrictList = async (params: GetDistrictListParams) => {
  const { data } = await Axios.get<any>('/settings/district', { params });
  return data;
};

/**
 * Get Carer Types list (https://jcabc-app-dev.appicidea.com/doc/#/Setting/SettingController_listCarerTypes)
 */

export const getCarerTypeList = async () => {
  const { data } = await Axios.get<any>('/settings/carerTypes');
  return data;
};

/**
 * Get Job Industry list (https://jcabc-app-dev.appicidea.com/doc/#/Setting/SettingController_listJobIndustries)
 */

export const getJobIndustryList = async () => {
  const { data } = await Axios.get<any>('/settings/jobIndustries');
  return data;
};
