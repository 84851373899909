import { IMAGE_URL, PDF_URL } from 'app/config';
import { OptionItems } from 'app/models';
import { getDistrictList, getRegionList } from 'app/services/SettingsService';
import { toast } from 'react-toastify';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const getPDFURL = (pdfSrc: string) => {
  if (pdfSrc && pdfSrc.includes('https')) {
    return pdfSrc;
  } else if (pdfSrc) {
    return `${PDF_URL}/original/${pdfSrc}`;
  }
  return '';
};

export const getInitialRegionDistrictObject = async (
  language: 'zh-HK' | 'en',
) => {
  const regionList = await getRegionList({ lang: language });
  let result = {};
  for (const region of Object.keys(regionList)) {
    const map = await getDistrictList({ lang: language, region: region });
    result = {
      ...result,
      [region]: {
        label: regionList[region],
        value: region,
        map,
        options: Object.keys(map).map(key => ({
          label: map[key],
          value: key,
        })),
      },
    };
  }
  return result;
};

export const getRegionDistrictObject = () => {
  try {
    const regionDistrict = localStorage.getItem('regionDistrict');
    if (regionDistrict) {
      return JSON.parse(regionDistrict);
    } else {
      throw new Error('regionDistrict is not a string');
    }
  } catch (err) {
    console.error(err);
    toast.warning('無法解析區域 Json。');
    return {};
  }
};

/**
 * Get all districts' map and options. First return is map and second is options.
 */
export const getAllDistrictMapAndOptions = () => {
  const regionDistrict = getRegionDistrictObject();
  let districtMap = {};
  let districtOptions: OptionItems[] = [];
  for (const item of Object.keys(regionDistrict)) {
    districtMap = { ...districtMap, ...regionDistrict[item].map };
    districtOptions = [...districtOptions, ...regionDistrict[item].options];
  }
  return { districtMap, districtOptions };
};

export const setRegionDistrictInLocalStorage = async () => {
  try {
    if (!localStorage.getItem('regionDistrict')) {
      const regionDistrict = await getInitialRegionDistrictObject('zh-HK');
      localStorage.setItem('regionDistrict', JSON.stringify(regionDistrict));
    }
  } catch (err) {
    console.error(err);
  }
};
