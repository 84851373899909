import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutType, LayoutWidth, SidebarType, ThemeType } from 'app/models';
import { AppThunk } from 'store';

interface LayoutState {
  layoutType: LayoutType;
  layoutWidth: LayoutWidth;
  leftSideBarTheme: ThemeType;
  leftSideBarType: SidebarType;
  topbarTheme: ThemeType;
  isPreloader: boolean;
  showRightSidebar: boolean;
  isMobile: boolean;
  showSidebar: boolean;
  leftMenu: boolean;
}

const initialState = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'light',
  leftSideBarType: 'default',
  topbarTheme: 'light',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
} as LayoutState;

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayoutType(state, action: PayloadAction<LayoutType>) {
      state.layoutType = action.payload;
    },
    changePreloader(state, action: PayloadAction<boolean>) {
      state.isPreloader = action.payload;
    },
    changeLayoutWidth(state, action: PayloadAction<LayoutWidth>) {
      state.layoutWidth = action.payload;
    },
    changeSidebarTheme(state, action: PayloadAction<ThemeType>) {
      state.leftSideBarTheme = action.payload;
    },
    changeSidebarType(state, action: PayloadAction<SidebarType>) {
      state.leftSideBarType = action.payload;
    },
    changeTopBarTheme(state, action: PayloadAction<ThemeType>) {
      state.topbarTheme = action.payload;
    },
    showRightSidebarState(state, action: PayloadAction<boolean>) {
      state.showRightSidebar = action.payload;
    },
    showSidebaer(state, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload;
    },
    toggleLeftMenu(state, action: PayloadAction<boolean>) {
      state.leftMenu = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  changeLayoutType,
  changePreloader,
  changeSidebarTheme,
  changeSidebarType,
  changeTopBarTheme,
  toggleLeftMenu,
  changeLayoutWidth,
  showRightSidebarState,
} = layoutSlice.actions;
export default layoutSlice.reducer;

// dispatch actions
export const showRightSidebarAction = (
  state: boolean,
): AppThunk => async dispatch => {
  dispatch(showRightSidebarState(state));
  manageBodyClass('right-bar-enabled', 'add');
};

export const changeTopbarThemeAction = (
  theme: ThemeType,
): AppThunk => async dispatch => {
  dispatch(changeTopBarTheme(theme));
  changeBodyAttribute('data-topbar', theme);
};

export const changeLeftSidebarTheme = (
  theme: ThemeType,
): AppThunk => async dispatch => {
  dispatch(changeSidebarTheme(theme));
  changeBodyAttribute('data-sidebar', theme);
};

export const changeLayoutAction = (
  layout: LayoutType,
): AppThunk => async dispatch => {
  dispatch(changeLayoutType(layout));
  if (layout === 'horizontal') {
    dispatch(changeTopBarTheme('dark'));
    document.body.removeAttribute('data-sidebar');
    document.body.removeAttribute('data-sidebar-size');
  } else {
    dispatch(changeTopBarTheme('light'));
  }
  changeBodyAttribute('data-layout', layout);
};

export const changeLayoutWidthAction = (
  width: LayoutWidth,
): AppThunk => async dispatch => {
  dispatch(changeLayoutWidth(width));
  if (width === 'boxed') {
    dispatch(changeSidebarType('icon'));
    changeBodyAttribute('data-layout-size', width);
    changeBodyAttribute('data-layout-scrollable', false);
  } else if (width === 'scrollable') {
    dispatch(changeSidebarType('default'));
    changeBodyAttribute('data-layout-scrollable', true);
  } else {
    dispatch(changeSidebarType('default'));
    changeBodyAttribute('data-layout-size', width);
    changeBodyAttribute('data-layout-scrollable', false);
  }
};

export const changeLeftSidebarType = ({
  sidebarType,
  isMobile,
}: {
  sidebarType: SidebarType;
  isMobile: boolean;
}): AppThunk => async dispatch => {
  dispatch(changeSidebarType(sidebarType));
  switch (sidebarType) {
    case 'compact':
      changeBodyAttribute('data-sidebar-size', 'small');
      manageBodyClass('sidebar-enable', 'remove');
      manageBodyClass('vertical-collpsed', 'remove');
      break;
    case 'icon':
      changeBodyAttribute('data-keep-enlarged', 'true');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'condensed':
      manageBodyClass('sidebar-enable', 'add');
      if (window.screen.width >= 992) {
        manageBodyClass('vertical-collpsed', 'remove');
        manageBodyClass('sidebar-enable', 'remove');
        manageBodyClass('vertical-collpsed', 'add');
        manageBodyClass('sidebar-enable', 'add');
      } else {
        manageBodyClass('sidebar-enable', 'add');
        manageBodyClass('vertical-collpsed', 'add');
      }
      break;
    default:
      changeBodyAttribute('data-sidebar-size', '');
      manageBodyClass('sidebar-enable', 'remove');
      if (!isMobile) {
        manageBodyClass('vertical-collpsed', 'remove');
      }
      break;
  }
};

function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

function manageBodyClass(cssClass, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}
