import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import '../filter.scss';

interface Props {
  placeholder?: string;
  disabled?: boolean;
  searchField: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}

const SearchBar = ({ searchField, placeholder, onChange, label }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [text, setText] = useState<string | null>(
    new URLSearchParams(location.search).get(searchField),
  );
  const [flag, setFlag] = useState<boolean>(true);

  const textOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _.debounce((searchText, params, flag) => {
      if (searchText) {
        params.set(searchField, searchText);
      } else {
        params.delete(searchField);
      }
      if (!flag) params.set('page', '1');
      history.push({ search: params.toString() });
    }, 500),
    [],
  );

  useEffect(() => {
    setFlag(false);
    if (text !== null) {
      const params = new URLSearchParams(location.search);
      debounceSearch(text, params, flag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, debounceSearch]);

  return (
    <div className="search-box mb-2 d-inline-block" style={{ width: '100%' }}>
      {label ? <Label>{label}</Label> : null}
      <div className="position-relative">
        <Input
          className="search-input-field"
          placeholder={placeholder ? placeholder : ''}
          value={text || ''}
          onChange={onChange || textOnChange}
        />
        <i className="bx bx-search-alt search-icon" />
      </div>
    </div>
  );
};

export default SearchBar;
