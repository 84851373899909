import {
  ColorOptionItems,
  EventApplicationStatus,
  EventStatus,
  OptionItems,
} from 'app/models';

export const EVENT_ROOTCATEGORY_OPTIONS = [
  { value: '本中心活動', label: '本中心活動' },
  {
    label: '其他中心活動',
    value: '其他中心活動',
  },
];
export const EVENT_STATUS_OPTIONS: OptionItems[] = [
  {
    label: '批准中',
    value: EventStatus.Pending,
  },
  {
    label: '已批准',
    value: EventStatus.Approved,
  },
  {
    label: '已取消',
    value: EventStatus.Rejected,
  },
];

export const EventStatusMap = new Map<string, ColorOptionItems>([
  [
    'Pending',
    { label: '批准中', value: EventStatus.Pending, colorType: 'secondary' },
  ],
  [
    'Approved',
    { label: '已批准', value: EventStatus.Approved, colorType: 'success' },
  ],
  [
    'Rejected',
    { label: '已取消', value: EventStatus.Rejected, colorType: 'danger' },
  ],
]);

export const EVENT_APPLICATION_STATUS_OPTIONS: OptionItems[] = [
  {
    label: '確認中',
    value: EventApplicationStatus.Pending,
  },
  {
    label: '已確認',
    value: EventApplicationStatus.Approved,
  },
  {
    label: '已取消',
    value: EventApplicationStatus.Rejected,
  },
];

export const EventApplicationStatusMap = new Map<string, ColorOptionItems>([
  [
    'Pending',
    {
      label: '確認中',
      value: EventApplicationStatus.Pending,
      colorType: 'secondary',
    },
  ],
  [
    'Approved',
    {
      label: '已確認',
      value: EventApplicationStatus.Approved,
      colorType: 'success',
    },
  ],
  [
    'Rejected',
    {
      label: '已取消',
      value: EventApplicationStatus.Rejected,
      colorType: 'danger',
    },
  ],
]);
