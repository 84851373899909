import { CompanyParams, CompanyResonse, Filter, ListResult } from '../models';
import Axios from './';

/**
 * get company list (https://jcabc-app-dev.appicidea.com/doc/#/Company/CompanyController_listCompany)
 */

export interface GetCompanyListParams extends Filter {
  name?: string;
}

export const getCompanyListData = async (params: GetCompanyListParams) => {
  const { data } = await Axios.get<ListResult<CompanyResonse>>('/companies', {
    params,
  });
  return data;
};

/**
 * create new company (https://jcabc-app-dev.appicidea.com/doc/#/Company/CompanyController_listCompany)
 */

export const createNewCompany = async (params: CompanyParams) => {
  const { data } = await Axios.post<CompanyResonse>('/companies', params);
  return data;
};

/**
 * get one company (https://jcabc-app-dev.appicidea.com/doc/#/Company/CompanyController_getCompanyById)
 */

export const getOneCompany = async (companyId: number | string) => {
  const { data } = await Axios.get<CompanyResonse>(`/companies/${companyId}`);
  return data;
};

/**
 * update one company (https://jcabc-app-dev.appicidea.com/doc/#/Company/CompanyController_updateCompanyById)
 */

export const updateOneCompany = async (
  companyId: number | string,
  params: CompanyParams,
) => {
  const { data } = await Axios.patch<CompanyResonse>(
    `/companies/${companyId}`,
    params,
  );
  return data;
};

/**
 * delete one company (https://jcabc-app-dev.appicidea.com/doc/#/Company/CompanyController_deleteCompanyById)
 */

export const deleteOneCompany = async (companyId: number | string) => {
  const { data } = await Axios.delete<any>(`/companies/${companyId}`);
  return data;
};
