export const BANNER_TYPE_OPTIONS = [
  {
    label: '中心活動',
    value: 'event',
  },
  { label: '最新資訊', value: 'news' },
  { label: '工作機會', value: 'job' },
  { label: '照顧秘笈', value: 'usefulInfo' },
  { label: '網址', value: 'url' },
];
