/**
 *
 * Asynchronously loads the component for TakeDownListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const TakeDownListPage = lazyLoad(
  () => import('./index'),
  module => module.TakeDownListPage,
);
