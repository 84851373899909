import { IMAGE_SIZE } from 'app/config';
import { uploadImage } from 'app/services/CommonService';
import BraftEditor, { ControlType, EditorState } from 'braft-editor';
import 'braft-editor/dist/index.css';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';

const HTML_EDITOR_CONTROL: ControlType[] = [
  'font-size',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'list-ul',
  'list-ol',
  'separator',
  'link',
  'separator',
  'hr',
  'separator',
  'media',
];

interface OtherProps {
  label: string;
  placeholder?: string;
}

const HTMLField = (props: OtherProps & FieldHookConfig<any>) => {
  const [field, meta, helpers] = useField(props);
  const { label, placeholder } = props;

  const editorImageValidation = file => {
    if (file.size > IMAGE_SIZE) {
      toast.success('封面圖片不可大於10MB');
    }
    return file.size < IMAGE_SIZE;
  };

  const editorImageUpload = async param => {
    const formData = new FormData();
    const { file, progress, success, error } = param;
    formData.append('file', file);
    try {
      const config = {
        onUploadProgress: progressEvent => {
          progress((progressEvent.loaded / progressEvent.total) * 100);
        },
      };
      const fileRes = await uploadImage(formData, config);
      success({
        url: `${fileRes.url}/original/${fileRes.fileName}`,
      });
    } catch (err) {
      error({
        msg: '上載圖片失敗，請重試。',
      });
    }
  };

  const unitExportFn = (unit, type, target) => {
    if (type === 'line-height') {
      return unit + 'px';
    }

    if (target === 'html') {
      return `${unit}px; line-height: ${Math.round(unit * 1.4)}px`;
    } else {
      return unit + 'px';
    }
  };

  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{props.label}</Label> : null}
      <BraftEditor
        placeholder={placeholder || '輸入內容...'}
        value={field.value}
        onChange={(html: EditorState) => helpers.setValue(html)}
        language="zh-hant"
        controls={HTML_EDITOR_CONTROL}
        converts={{ unitExportFn }}
        imageControls={[
          'float-left',
          'float-right',
          'align-left',
          'align-center',
          'align-right',
          'size',
          'remove',
        ]}
        media={{
          uploadFn: editorImageUpload,
          validateFn: editorImageValidation,
          accepts: {
            image: 'image/png, image/jpeg, image/jpg',
            video: false,
            audio: false,
          },
          externals: {
            image: true,
            video: false,
            audio: false,
          },
          pasteImage: false,
        }}
        fontSizes={[10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default HTMLField;
