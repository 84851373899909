import { JobApplicationStatusMap } from 'app/constants';
import { JobApplication, ListColumn } from 'app/models/';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';

const JobApplicationListColumns: (
  changeStatusFunc,
  userRole,
  loading,
) => ListColumn<JobApplication>[] = (changeStatusFunc, userRole, loading) => [
  {
    text: '編號',
    dataField: 'jobApplicationId',
    canSort: true,
    formatter: (jobApplication: JobApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">#{jobApplication.jobApplicationId}</div>
        </h5>
      </>
    ),
  },
  {
    text: ' 會員編號 | 會員 | 聯絡電話',
    dataField: 'userId',
    canSort: false,
    formatter: (jobApplication: JobApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <Link to={`/users/detail/${jobApplication.userId}`}>
            <div className="text-dark">
              {`#${jobApplication.userId} | ${
                jobApplication.User ? jobApplication.User.displayName : '-'
              }`}
            </div>
            <div className="text-dark mt-1 font-size-12">
              聯絡電話:{' '}
              {jobApplication.User ? jobApplication.User.phoneNumber : '-'}
            </div>
          </Link>
        </h5>
      </>
    ),
  },
  {
    text: '工作機會',
    dataField: 'jobId',
    canSort: true,
    formatter: (jobApplication: JobApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-column">
          <div className="text-dark">
            <Link
              className="text-dark"
              to={`/jobs/items/detail/${jobApplication.jobId}`}
            >
              {jobApplication.Job
                ? `#${jobApplication.jobId} | ${jobApplication.Job.title}`
                : '-'}
            </Link>
          </div>
        </h5>
      </>
    ),
  },

  {
    text: '狀態',
    dataField: 'status',
    canSort: true,
    formatter: (jobApplication: JobApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">
            {jobApplication?.status ? (
              <Badge
                pill
                className={`badge-soft-${
                  JobApplicationStatusMap.get(jobApplication.status)?.colorType
                } ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
              >
                {JobApplicationStatusMap.get(jobApplication.status)?.label}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '更改狀態',
    dataField: 'menu',
    formatter: (jobApplication: JobApplication) => (
      <h5 className="font-size-14 mb-1">
        <div className="text-dark d-flex flex-row">
          <>
            <Button
              className="ms-1 py-1 font-size-12"
              color="success"
              onClick={() =>
                changeStatusFunc(jobApplication.jobApplicationId, 'Approved')
              }
              disabled={loading}
            >
              完成
            </Button>
            <Button
              className="ms-1 py-1 font-size-12"
              color="danger"
              onClick={() =>
                changeStatusFunc(jobApplication.jobApplicationId, 'Rejected')
              }
              disabled={loading}
            >
              刪除
            </Button>
          </>
        </div>
      </h5>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    canSort: true,
    formatter: (jobApplication: JobApplication) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(jobApplication.createdAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '更新時間',
    dataField: 'updatedAt',
    canSort: true,
    formatter: (jobApplication: JobApplication) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(jobApplication.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '履歷',
    formatter: (jobApplication: JobApplication) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          {jobApplication?.resumeId ? (
            <Link
              to={`/users/resumes/${jobApplication.resumeId}`}
              id={'view' + jobApplication.jobApplicationId}
              target="_blank"
            >
              <i className="bx bx-show-alt" />
              <UncontrolledTooltip
                placement="top"
                target={'view' + jobApplication.jobApplicationId}
              >
                查看履歷
              </UncontrolledTooltip>
            </Link>
          ) : (
            <div className="font-size-12 fw-bold text-secondary px-2">
              沒有履歷
            </div>
          )}
        </li>
      </ul>
    ),
  },
];

export default JobApplicationListColumns;
