import { Filter, Job, JobApplication, ListResult } from 'app/models';
import Axios from '.';

/**
 * Get Job list (https://jcabc-app-dev.appicidea.com/doc/#/Job/JobController_listJob)
 */

export interface GetJobListParams extends Filter {
  title?: string;
  region?: string;
  district?: string;
  companyId?: string;
  industry?: string;
  payrollPeriod?: string;
  salaryFrom?: string;
  salaryTo?: string;
  employmentType?: string;
  status?: string;
}

export const getJobListData = async (params: GetJobListParams) => {
  const { data } = await Axios.get<ListResult<Job>>('/jobs', {
    params,
  });
  return data;
};

/**
 * Create new Job  (https://jcabc-app-dev.appicidea.com/doc/#/Job/JobController_createJob)
 */

export interface CreateNewJobParams {
  referenceCode: string;
  employmentType: string;
  industry: string;
  payrollPeriod: string;
  salary: string;
  title: string;
  description: string;
  vacancy: string;
  postedAt: string;
  endedAt: string;
  measures: string;
  workingDays: string;
  workingHours: string;
  requirements: string;
  others: string;
  region: string | undefined;
  districts: string[];
  address: string;
  contactPerson: string;
  contactPhone: string;
  isResumeFirst: boolean | null;
  companyId: string | number | null;
  status?: string;
}

export const createNewJob = async (params: CreateNewJobParams) => {
  const { data } = await Axios.post<Job>(`/jobs`, params);
  return data;
};

/**
 * Get one Job (https://jcabc-app-dev.appicidea.com/doc/#/Job/JobController_getJobById)
 */

export const getOneJob = async (jobId: number | string) => {
  const { data } = await Axios.get<Job>(`/jobs/${jobId}`);
  return data;
};

/**
 * Update one Job (https://jcabc-app-dev.appicidea.com/doc/#/Job/JobController_updateJobById)
 */
export const updateOneJob = async (
  jobId: number,
  params: Partial<CreateNewJobParams>,
) => {
  const { data } = await Axios.patch<string>(`/jobs/${jobId}`, params);
  return data;
};

/**
 * Delete one Job(https://jcabc-app-dev.appicidea.com/doc/#/Job/JobController_deleteJobById)
 */

export const deleteOneJob = async (jobId: number) => {
  const { data } = await Axios.delete<string>(`/jobs/${jobId}`);
  return data;
};

/**
 *  Get All Job Application  (https://jcabc-app-dev.appicidea.com/doc/#/Job%20Application/JobApplicationController_listJobApplications)
 */

export interface GetJobApplicationsListParams extends Filter {
  userId?: string;
  jobId?: string;
  companyId?: string;
  status?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export const getJobApplicationsList = async (
  params: GetJobApplicationsListParams,
) => {
  const { data } = await Axios.get<ListResult<JobApplication>>(
    `/jobApplications`,
    {
      params,
    },
  );
  return data;
};

/**
 *  Get One Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Job%20Application/JobApplicationController_getJobApplication)
 */

export const getOneJobApplication = async (jobApplicationId: string) => {
  const { data } = await Axios.get<JobApplication>(
    `/jobApplications/${jobApplicationId}`,
  );
  return data;
};

/**
 *  Update One Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Job%20Application/JobApplicationController_updateJobApplication)
 */

export interface UpdateOneJobApplicationParams {
  status?: string;
}

export const updateOneJobApplication = async (
  jobApplicationId: number,
  params: UpdateOneJobApplicationParams,
) => {
  const { data } = await Axios.patch<null>(
    `/jobApplications/${jobApplicationId}`,
    params,
  );
  return data;
};

/**
 *  Delete one Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Job%20Application/JobApplicationController_deleteJobApplication)
 */
export const deleteOneJobApplication = async (jobApplicationId: number) => {
  const { data } = await Axios.delete<string>(
    `/jobApplications/${jobApplicationId}`,
  );
  return data;
};
