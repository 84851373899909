import { PAGE_SIZE } from 'app/config';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface Props {
  count: number;
  current: number;
  pageSize?: number;
  disableAutoScrollToTop?: boolean;
  onChange?: (page: number) => void;
}

function CustomPagination({
  count,
  current,
  pageSize,
  disableAutoScrollToTop,
  onChange,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const pageOnChange = (page: number) => {
    if (onChange) {
      onChange(page);
    } else {
      const params = new URLSearchParams(location.search);
      params.set('page', page.toString());
      history.push({ search: params.toString() });
    }
  };
  const total = Math.ceil(count / (pageSize ? pageSize : PAGE_SIZE));
  let pages: number[] = [];
  for (let i = 0; i < total; i++) {
    pages.push(i + 1);
  }
  const nextDisable = current === total;
  const prevDisable = current === 1;
  let start = current - 5,
    end = current + 5;
  if (start < 0) {
    start = 0;
    end = start + 10;
  } else if (end > total) {
    end = total;
    start = end - 10;
  }

  return (
    <Pagination aria-label="Page navigation example">
      {pages.length > 0 ? (
        <>
          <PaginationItem
            disabled={prevDisable}
            onClick={() => {
              if (!prevDisable) {
                pageOnChange(1);
              }
            }}
          >
            <PaginationLink
              first
              href={disableAutoScrollToTop ? '' : '#'}
              style={{ fontSize: 20 }}
            />
          </PaginationItem>
          <PaginationItem
            disabled={prevDisable}
            onClick={() => {
              if (!prevDisable) {
                pageOnChange(current - 1);
              }
            }}
          >
            <PaginationLink
              previous
              href={disableAutoScrollToTop ? '' : '#'}
              style={{ fontSize: 20 }}
            />
          </PaginationItem>
        </>
      ) : null}

      {pages.map((page, index) => {
        if (index >= start && index < end)
          return (
            <PaginationItem
              active={page === current}
              onClick={() => pageOnChange(page)}
              key={page}
              title={page.toString()}
            >
              <PaginationLink href={disableAutoScrollToTop ? '' : '#'}>
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        return null;
      })}
      {pages.length > 0 ? (
        <>
          <PaginationItem
            disabled={nextDisable}
            onClick={() => {
              if (!nextDisable) {
                pageOnChange(current + 1);
              }
            }}
            title="next page"
          >
            <PaginationLink
              next
              href={disableAutoScrollToTop ? '' : '#'}
              style={{ fontSize: 20 }}
            />
          </PaginationItem>
          <PaginationItem
            disabled={nextDisable}
            onClick={() => {
              if (!nextDisable) {
                pageOnChange(total);
              }
            }}
            title="last page"
          >
            <PaginationLink
              last
              href={disableAutoScrollToTop ? '' : '#'}
              style={{ fontSize: 20 }}
            />
          </PaginationItem>
        </>
      ) : null}
    </Pagination>
  );
}

export default CustomPagination;
