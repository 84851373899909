import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import Dialog from 'app/components/Modal/Modal';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME, PAGE_SIZE, SUBPAGE_PAGE_SIZE } from 'app/config';
import {
  EVENT_APPLICATION_STATUS_OPTIONS,
  JOB_APPLICATION_STATUS_OPTIONS,
} from 'app/constants';
import {
  getAllDistrictMapAndOptions,
  getImageURL,
} from 'app/helpers/CommonHelper';
import { useAutoComplete } from 'app/hooks';
import {
  CompanyResonse,
  Event,
  EventApplication,
  Job,
  JobApplication,
  User,
} from 'app/models';
import EventApplicationListColumns from 'app/pages/EventApplicationPage/EventApplicationListPage/EventApplicationListColumns';
import JobApplicationListColumns from 'app/pages/JobApplicationPage/JobApplicationListPage/JobApplicationListColumns';
import {
  getCompanyListData,
  GetCompanyListParams,
  getOneCompany,
} from 'app/services/CompanyServices';
import {
  batchUpdateEventApplication,
  getEventApplicationsList,
  getEventList,
  GetEventListParams,
  getOneEvent,
} from 'app/services/EventService';
import {
  getJobApplicationsList,
  getJobListData,
  GetJobListParams,
  getOneJob,
  updateOneJobApplication,
} from 'app/services/JobService';
import {
  activateUserAccount,
  disactivateUserAccount,
  getOneUser,
} from 'app/services/UserService';
import React, { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table as ReactstrapTable,
  TabPane,
} from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';

interface FormQuery {
  userId: string;
}

export const UserDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const params = useParams<FormQuery>();
  const { auth } = useSelector((rootState: RootState) => rootState);
  const currentRole = auth.Auth ? auth?.Auth?.role : '';
  const { districtMap } = getAllDistrictMapAndOptions();
  const [user, setUser] = useState<User>();
  const [userId, setUserId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activateVisible, setActivateVisible] = useState<boolean>(false);
  const [disactivateVisible, setDisactivateVisible] = useState<boolean>(false);

  //Tab variables
  const [activeTabNum, setActiveTabNum] = useState<string>('1');
  //State for tab 1
  const [eventApplicationMap, setEventApplicationMap] = useState<
    Record<number, EventApplication>
  >({});
  const [eventApplicationList, setEventApplicationList] = useState<
    EventApplication[]
  >([]);
  const [eventApplicationpage, setEventApplicationpage] = useState<number>(1);
  const [eventCount, setEventCount] = useState<number>(0);
  const [clickedEventApplicationId, setClickedEventApplicationId] = useState<
    number
  >(0);
  const [approveVisible, setApproveVisible] = useState<boolean>(false);
  const [cancelVisible, setCancelVisible] = useState<boolean>(false);
  const [batchApproveVisible, setBatchApproveVisible] = useState<boolean>(
    false,
  );
  const [batchRejectVisible, setBatchRejecVisible] = useState<boolean>(false);
  //State for tab 2
  const [jobApplicationList, setJobApplicationList] = useState<
    JobApplication[]
  >([]);
  const [jobApplicationPage, setJobApplicationPage] = useState<number>(1);
  const [jobCount, setJobCount] = useState<number>(0);
  const [changeStatusVisible, setChangeStatusVisible] = useState<boolean>(
    false,
  );
  const [clickedJobApplicationId, setClickedJobApplicationId] = useState<
    number
  >(0);
  const [clickedJobStatus, setClickedJobStatus] = useState<string>('');
  const {
    searchText: eventsText,
    setSearchText: setEventText,
    dataList: eventList,
    onMenuScrollToBottom: eventScrollToBottom,
    updateDataListWithItemCall: updateEventListWithItemCall,
  } = useAutoComplete<GetEventListParams, Event>({
    getItemCall: getOneEvent,
    getListCall: getEventList,
    labelRender: (item: Event) => ({
      label: `#${item.eventId} | ${item.title}`,
      value: item.eventId.toString(),
    }),
    keyExtracter: (item: Event) => item.eventId,
    searchTextFieldName: 'title',
  });

  const {
    searchText: companyText,
    setSearchText: setCompanyText,
    dataList: companyList,
    onMenuScrollToBottom: onEventMenuScrollToBottom,
    updateDataListWithItemCall: updateCompanyListWithItemCall,
  } = useAutoComplete<GetCompanyListParams, CompanyResonse>({
    getItemCall: getOneCompany,
    getListCall: getCompanyListData,
    labelRender: (item: CompanyResonse) => ({
      label: `#${item.companyId} | ${item.name}`,
      value: item.companyId.toString(),
    }),
    keyExtracter: (item: CompanyResonse) => item.companyId,
    searchTextFieldName: 'name',
  });

  const {
    searchText: jobText,
    setSearchText: setJobText,
    dataList: jobList,
    onMenuScrollToBottom: onJobMenuScrollToBottom,
    updateDataListWithItemCall: updateJobListWithItemCall,
  } = useAutoComplete<GetJobListParams, Job>({
    getItemCall: getOneJob,
    getListCall: getJobListData,
    labelRender: (item: Job) => ({
      label: `#${item.jobId} | ${item.title} `,
      value: item.jobId.toString(),
    }),
    keyExtracter: (item: Job) => item.jobId,
    searchTextFieldName: 'title',
  });

  const initPage = useCallback(async () => {
    dispatch(changePreloader(true));
    const { userId: _userId } = params;
    try {
      if (_userId) {
        const userRes = await getOneUser(parseInt(_userId));
        setUserId(parseInt(_userId));
        setUser(userRes);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initPage]);

  //Function for User
  const activateUserSubmit = async () => {
    try {
      setLoading(true);
      if (userId) {
        await activateUserAccount(userId);
        toast.success('啟用成功。');
        setActivateVisible(false);
        await new Promise(resolve => setTimeout(resolve, 1200));
        history.go(0);
      }
      setLoading(false);
    } catch (err) {
      toast.error('啟用失敗，請重試。');
      setActivateVisible(false);
    }
  };
  const disactivateUserSubmit = async () => {
    try {
      setLoading(true);
      if (userId) {
        await disactivateUserAccount(userId);
        toast.success('停用成功。');
        setDisactivateVisible(false);
        await new Promise(resolve => setTimeout(resolve, 1200));
        history.go(0);
      }
      setLoading(false);
    } catch (err) {
      toast.error('停用失敗，請重試。');
      setDisactivateVisible(false);
    }
  };

  // Function in Tab

  const getEventApplicationRecords = useCallback(async () => {
    try {
      if (userId && activeTabNum === '1') {
        const params = new URLSearchParams(location.search);
        const eventId = params.get('eventId');
        const asc = params.get('asc');
        const desc = params.get('desc');
        const status = params.get('status');
        const createdAtFrom = params.get('createdAtFrom');
        const createdAtTo = params.get('createdAtTo');

        const applicationRes = await getEventApplicationsList({
          limit: SUBPAGE_PAGE_SIZE,
          offset: (eventApplicationpage - 1) * SUBPAGE_PAGE_SIZE,
          userId: userId ? userId.toString() : undefined,
          eventId: eventId ? eventId : undefined,
          asc: asc ? asc : undefined,
          desc: desc ? desc : undefined,
          status: status ? status : undefined,
          createdAtFrom: createdAtFrom ? createdAtFrom : undefined,
          createdAtTo: createdAtTo ? createdAtTo : undefined,
        });
        setEventApplicationList(applicationRes.rows);
        setEventCount(applicationRes.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [userId, activeTabNum, location.search, eventApplicationpage]);
  useEffect(() => {
    getEventApplicationRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventApplicationRecords]);

  const getJobApplicationRecords = useCallback(async () => {
    try {
      if (userId && activeTabNum === '2') {
        const params = new URLSearchParams(location.search);
        const jobId = params.get('jobId');
        const companyId = params.get('companyId');
        const asc = params.get('asc');
        const desc = params.get('desc');
        const status = params.get('status');

        const applicationRes = await getJobApplicationsList({
          limit: SUBPAGE_PAGE_SIZE,
          offset: (jobApplicationPage - 1) * SUBPAGE_PAGE_SIZE,
          userId: userId ? userId.toString() : undefined,
          jobId: jobId ? jobId : undefined,
          companyId: companyId ? companyId : undefined,
          asc: asc ? asc : undefined,
          desc: desc ? desc : undefined,
          status: status ? status : undefined,
        });
        setJobApplicationList(applicationRes.rows);
        setJobCount(applicationRes.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [userId, activeTabNum, location.search, jobApplicationPage]);
  useEffect(() => {
    getJobApplicationRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJobApplicationRecords]);

  const itemOnCheck = (item: EventApplication) => {
    const tempMap = { ...eventApplicationMap };
    if (tempMap[item.eventApplicationId]) {
      delete tempMap[item.eventApplicationId];
    } else {
      tempMap[item.eventApplicationId] = item;
    }
    setEventApplicationMap(tempMap);
  };
  //Function for tab 1
  const checkAllOnPress = () => {
    const tempMap = { ...eventApplicationMap };
    const length = Object.keys(tempMap).length;
    if (
      length === PAGE_SIZE ||
      length === eventApplicationList.length ||
      length ===
        eventApplicationList.filter(
          (item: EventApplication) => item.status === 'Pending',
        ).length
    ) {
      setEventApplicationMap({});
    } else if (length < PAGE_SIZE) {
      const selectedMap: Record<
        number,
        EventApplication
      > = eventApplicationList.reduce((map, item) => {
        if (item.status === 'Pending') {
          map[item.eventApplicationId] = item;
        }
        return map;
      }, {});
      setEventApplicationMap(selectedMap);
    }
  };

  const approveButtonOnClick = async (userId: string) => {
    setClickedEventApplicationId(parseInt(userId));

    setApproveVisible(true);
  };

  const approveConfirm = async () => {
    setLoading(true);
    try {
      await batchUpdateEventApplication([
        {
          eventApplicationId: clickedEventApplicationId,
          status: 'Approved',
        },
      ]);
      toast.success('成功批准待確認名額');
      setLoading(false);
      setApproveVisible(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.error('批准待確認名額失敗，請重試。');
      setLoading(false);
      setApproveVisible(false);
    }
  };
  const cancelButtonOnClick = async (userId: string) => {
    setClickedEventApplicationId(parseInt(userId));
    setCancelVisible(true);
  };

  const cancelConfirm = async () => {
    setLoading(true);
    try {
      await batchUpdateEventApplication([
        {
          eventApplicationId: clickedEventApplicationId,
          status: 'Rejected',
        },
      ]);
      toast.success('成功拒絕待確認名額');
      setLoading(false);
      setCancelVisible(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.error('拒絕失敗待確認名額，請重試。');
      setLoading(false);
      setCancelVisible(false);
    }
  };

  const batchApproveConfirm = async () => {
    setLoading(true);
    try {
      const eventApplicationsArray = Object.keys(eventApplicationMap).map(
        (key: string) => ({
          eventApplicationId: parseInt(key),
          status: 'Approved',
        }),
      );
      await batchUpdateEventApplication(eventApplicationsArray);
      toast.success('成功批准所有已選待確認名額。');
      setEventApplicationMap({});
      setBatchApproveVisible(false);
      setLoading(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.warning('批准所有已選待確認名額，請重試。');
      setLoading(false);
      setBatchApproveVisible(false);
    }
  };

  const batchRejectConfirm = async () => {
    setLoading(true);
    try {
      const eventApplicationsArray = Object.keys(eventApplicationMap).map(
        (key: string) => ({
          eventApplicationId: parseInt(key),
          status: 'Rejected',
        }),
      );
      await batchUpdateEventApplication(eventApplicationsArray);
      setEventApplicationMap({});
      toast.success('成功拒絕所有已選待確認名額。');
      setBatchRejecVisible(false);
      setLoading(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.warning('拒絕所有已選待確認名額失敗，請重試。');
      setLoading(false);
      setBatchRejecVisible(false);
    }
  };
  //Function for tab 2
  const changeStatusButtonOnClick = async (
    applicationId: string,
    status: string,
  ) => {
    setClickedJobApplicationId(parseInt(applicationId));
    setClickedJobStatus(status);
    setChangeStatusVisible(true);
  };
  const changeStatusConfirm = async () => {
    setLoading(true);
    try {
      if (clickedJobApplicationId) {
        await updateOneJobApplication(clickedJobApplicationId, {
          status: clickedJobStatus,
        });
        toast.success('成功確更改申請記錄狀態');
        setLoading(false);
        setChangeStatusVisible(false);
        getJobApplicationRecords();
        // await new Promise(resolve => setTimeout(resolve, 1200));
        // history.go(0);
      }
    } catch (err) {
      toast.error('更改申請記錄狀態失敗，請重試。');
      setLoading(false);
      setChangeStatusVisible(false);
    }
  };

  const TITLE = `檢視會員`;
  const BreadcrumbsOptions = [
    { title: '會員', path: '/users' },
    { title: '會員列表', path: '/users' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="檢視會員" breadcrumbItems={BreadcrumbsOptions} />
          {!isPreloader ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row className="mb-5 mt-4">
                      <Col sm={9}>
                        <Row>
                          <Col
                            sm={3}
                            className="d-sm-block d-flex flex-xs-row  justify-content-center"
                          >
                            <div className="avatar-xl">
                              <span className="avatar-title rounded-circle font-size-24 bg-light text-secondary">
                                {user?.avatar ? (
                                  <img
                                    className="rounded-circle avatar-xl"
                                    src={getImageURL(user.avatar)}
                                    alt=""
                                    style={{ objectFit: 'cover' }}
                                  />
                                ) : user?.displayName ? (
                                  user?.displayName.charAt(0)
                                ) : (
                                  <i className="bx bx-user" />
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col sm={9}>
                            <ReactstrapTable borderless>
                              <tbody>
                                <tr>
                                  <td>
                                    <h6 className="mb-1 font-size-14">
                                      會員編號 : #
                                      {user?.userId ? user?.userId : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      會員名稱 :{' '}
                                      {user?.displayName
                                        ? user?.displayName
                                        : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      電話號碼 :{' '}
                                      {user?.phoneNumber
                                        ? user?.phoneNumber
                                        : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      照顧類型 :{' '}
                                      {user
                                        ? user.carerRoles?.map(
                                            (
                                              careRole: string,
                                              index: number,
                                            ) => (
                                              <React.Fragment key={index}>
                                                <Badge
                                                  key={careRole}
                                                  pill
                                                  className="badge-soft-primary me-1 mb-1 p-1 font-size-12"
                                                >
                                                  {careRole ? careRole : '-'}
                                                </Badge>

                                                {index % 3 === 2 ? (
                                                  <br />
                                                ) : null}
                                              </React.Fragment>
                                            ),
                                          )
                                        : '-'}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-1 font-size-14">
                                      區域 :{' '}
                                      {user?.district
                                        ? districtMap[user?.district]
                                        : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      性別 :{' '}
                                      {user?.sex === 'male' ? '男' : '女'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      出世日期 :{' '}
                                      {user?.dateOfBirth
                                        ? user?.dateOfBirth
                                        : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      狀態 :
                                      {user?.status === '已啟用' ||
                                      user?.status === null ? (
                                        <Badge
                                          pill
                                          className={`badge-soft-success ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
                                        >
                                          已啟用
                                        </Badge>
                                      ) : (
                                        <Badge
                                          pill
                                          className={`badge-soft-danger ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
                                        >
                                          已停用
                                        </Badge>
                                      )}
                                    </h6>
                                  </td>
                                </tr>
                              </tbody>
                            </ReactstrapTable>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        sm={3}
                        className="d-flex  flex-row-reverse justify-content-center align-items-center"
                      >
                        {/* <Button
                              type="button"
                              className="ms-2"
                              color="success"
                              onClick={() => setPointVisible(true)}
                            >
                              增加積分
                            </Button> */}
                        {user?.status !== '已啟用' ? (
                          <Button
                            className="ms-1"
                            type="button"
                            color="success"
                            onClick={() => setActivateVisible(true)}
                          >
                            啟用帳戶
                          </Button>
                        ) : (
                          <Button
                            className="ms-1"
                            type="button"
                            color="danger"
                            onClick={() => setDisactivateVisible(true)}
                          >
                            停用帳戶
                          </Button>
                        )}
                        <Link
                          to={`/users/edit/${user?.userId}`}
                          className="btn btn-warning bg-warning  "
                        >
                          編輯
                        </Link>
                        <Link
                          className={`btn btn-info me-1 ${
                            !user?.resumeId && 'disabled-link'
                          }`}
                          to={`/users/resumes/${user?.resumeId}`}
                          id={'view' + user?.userId}
                          target="_blank"
                        >
                          查看履歷
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={`font-size-14 ${
                                activeTabNum === '1' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setActiveTabNum('1');
                              }}
                            >
                              活動報名記錄
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`font-size-14 ${
                                activeTabNum === '2' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setActiveTabNum('2');
                              }}
                            >
                              工作申請記錄
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent className="mt-2" activeTab={activeTabNum}>
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12">
                                <CardHeader className="bg-white text-dark d-flex justify-content-between pt-3">
                                  <CardTitle className="font-size-20">
                                    活動報名記錄
                                  </CardTitle>
                                  <CardTitle className="font-size-20">
                                    總數: {eventCount}
                                  </CardTitle>
                                </CardHeader>

                                <CardBody>
                                  <Row>
                                    <Col lg={3} sm={12}>
                                      <AutoCompleteSelect
                                        name="eventId"
                                        label="中心活動"
                                        placeholder="中心活動"
                                        options={eventList}
                                        onMenuScrollToBottom={
                                          eventScrollToBottom
                                        }
                                        inputValue={eventsText}
                                        onInputChange={text =>
                                          setEventText(text)
                                        }
                                        getOneData={updateEventListWithItemCall}
                                      />
                                    </Col>
                                    <Col lg={3} sm={12}>
                                      <SelectFilter
                                        label="狀態"
                                        placeholder="狀態"
                                        name="status"
                                        options={
                                          EVENT_APPLICATION_STATUS_OPTIONS
                                        }
                                        onChange={e =>
                                          setEventApplicationMap({})
                                        }
                                        isClearable
                                      />
                                    </Col>

                                    <Col lg={3} sm={12}>
                                      <DatePickerFilter
                                        label="創建日期範圍"
                                        name="createdAtFrom"
                                        name2="createdAtTo"
                                        placeholder="創建日期範圍"
                                        mode="range"
                                        isClearable
                                      />
                                    </Col>
                                    <Col
                                      lg={3}
                                      sm={12}
                                      className="d-flex flex-row-reverse align-items-end"
                                    >
                                      <div>
                                        <Button
                                          className="ms-1 mb-2"
                                          color="success"
                                          onClick={() =>
                                            setBatchApproveVisible(true)
                                          }
                                          disabled={
                                            Object.keys(eventApplicationMap)
                                              .length === 0 || loading
                                          }
                                        >
                                          批准
                                        </Button>
                                        <Button
                                          className="ms-1 me-1 mb-2"
                                          color="danger"
                                          onClick={() =>
                                            setBatchRejecVisible(true)
                                          }
                                          disabled={
                                            Object.keys(eventApplicationMap)
                                              .length === 0 || loading
                                          }
                                        >
                                          拒絕
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <Table
                                          columns={EventApplicationListColumns(
                                            approveButtonOnClick,
                                            cancelButtonOnClick,
                                            loading,
                                          )}
                                          isAllowSorting={false}
                                          keyField="eventApplicationId"
                                          data={eventApplicationList || []}
                                          checkableConfig={{
                                            isCheckBoxShow: (
                                              item: EventApplication,
                                            ) => item.status === 'Pending',
                                            onCheck: itemOnCheck,
                                            selected: eventApplicationMap,
                                            checkAll: checkAllOnPress,
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-3">
                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                      <Pagination
                                        current={eventApplicationpage}
                                        count={eventCount}
                                        pageSize={SUBPAGE_PAGE_SIZE}
                                        disableAutoScrollToTop={true}
                                        onChange={(page: number) => {
                                          setEventApplicationpage(page);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12">
                                <CardHeader className="bg-white text-dark d-flex justify-content-between pt-3">
                                  <CardTitle className="font-size-20">
                                    工作申請記錄
                                  </CardTitle>
                                  <CardTitle className="font-size-20">
                                    總數: {jobCount}
                                  </CardTitle>
                                </CardHeader>

                                <CardBody>
                                  <Row>
                                    <Col sm={12}>
                                      <Row>
                                        <Col lg={4}>
                                          <AutoCompleteSelect
                                            name="companyId"
                                            label="公司"
                                            placeholder="公司"
                                            inputValue={companyText}
                                            onInputChange={setCompanyText}
                                            options={companyList}
                                            onMenuScrollToBottom={
                                              onEventMenuScrollToBottom
                                            }
                                            getOneData={
                                              updateCompanyListWithItemCall
                                            }
                                          />
                                        </Col>
                                        <Col lg={4} sm={12}>
                                          <AutoCompleteSelect
                                            name="jobId"
                                            label="工作機會"
                                            placeholder="工作機會"
                                            inputValue={jobText}
                                            onInputChange={setJobText}
                                            options={jobList}
                                            onMenuScrollToBottom={
                                              onJobMenuScrollToBottom
                                            }
                                            getOneData={
                                              updateJobListWithItemCall
                                            }
                                          />
                                        </Col>
                                        <Col lg={4} sm={12}>
                                          <SelectFilter
                                            label="狀態"
                                            placeholder="狀態"
                                            name="status"
                                            options={
                                              JOB_APPLICATION_STATUS_OPTIONS
                                            }
                                            isClearable
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <Table
                                          columns={JobApplicationListColumns(
                                            changeStatusButtonOnClick,
                                            currentRole,
                                            loading,
                                          )}
                                          isAllowSorting={false}
                                          keyField="jobApplicationId"
                                          data={jobApplicationList || []}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-3">
                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                      <Pagination
                                        current={jobApplicationPage}
                                        count={jobCount}
                                        pageSize={SUBPAGE_PAGE_SIZE}
                                        disableAutoScrollToTop={true}
                                        onChange={(page: number) => {
                                          setJobApplicationPage(page);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Dialog
          visible={cancelVisible}
          title="拒絕待確認名額"
          onClose={() => setCancelVisible(false)}
          loading={loading}
          onConfirm={cancelConfirm}
        >
          <p>{`確定要拒絕待確認名額（編號: ${clickedEventApplicationId}）?`}</p>
        </Dialog>
        <Dialog
          visible={approveVisible}
          title={`批准待確認名額`}
          onClose={() => setApproveVisible(false)}
          loading={loading}
          onConfirm={approveConfirm}
        >
          <p>{`確定要批准待確認名額 (編號: ${clickedEventApplicationId})`}</p>
        </Dialog>
        <Dialog
          visible={batchApproveVisible}
          title={`批准已選待確認名額數量: ${
            Object.keys(eventApplicationMap).length
          }`}
          onClose={() => setBatchApproveVisible(false)}
          loading={loading}
          onConfirm={batchApproveConfirm}
        >
          <p>{`確定要批准待所有已選確認名額?`}</p>
        </Dialog>
        <Dialog
          visible={batchRejectVisible}
          title={`拒絕已選待確認名額數量: ${
            Object.keys(eventApplicationMap).length
          }`}
          onClose={() => setBatchRejecVisible(false)}
          loading={loading}
          onConfirm={batchRejectConfirm}
        >
          <p>{`確定要拒絕所有已選待確認名額?`}</p>
        </Dialog>
        <Dialog
          visible={activateVisible}
          title={`啟用會員帳戶`}
          onClose={() => setActivateVisible(false)}
          loading={loading}
          onConfirm={activateUserSubmit}
        >
          <p>{`確定要啟用這個會員帳戶?`}</p>
        </Dialog>
        <Dialog
          visible={disactivateVisible}
          title={`停用會員帳戶`}
          onClose={() => setDisactivateVisible(false)}
          loading={loading}
          onConfirm={disactivateUserSubmit}
        >
          <p>{`確定要停用這個會員帳戶?`}</p>
        </Dialog>
        <Dialog
          visible={changeStatusVisible}
          title="更改狀態"
          onClose={() => setChangeStatusVisible(false)}
          loading={loading}
          onConfirm={changeStatusConfirm}
        >
          <p>{`確定要更改申請記錄狀態（編號: ${clickedJobApplicationId}）?`}</p>
        </Dialog>
      </div>
    </>
  );
};
