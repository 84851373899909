import Breadcrumbs from 'app/components/Common/Breadcrumb';
import TableCount from 'app/components/Common/TableCount';
import { DatePickerFilter, SelectFilter } from 'app/components/Filter';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME } from 'app/config';
import {
  EVENT_ROOTCATEGORY_OPTIONS,
  EVENT_STATUS_OPTIONS,
} from 'app/constants';
import { YES_NO_OPTIONS } from 'app/constants/Common';
import { getRegionDistrictObject } from 'app/helpers/CommonHelper';
import { useTableComponentForListPage } from 'app/hooks';
import { AdminRoleEnum, Event, EventCategory, OptionItems } from 'app/models/';
import { getEventForCompanyAdmin } from 'app/services/AdminServices';
import {
  getEventCategoryList,
  getEventList,
  GetEventListParams,
} from 'app/services/EventService';
import { getCarerTypeList } from 'app/services/SettingsService';
import { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { RootState } from 'store';
import EventListColumns from './EventListColumns';

export const EventListPage = () => {
  const regionDistrict = getRegionDistrictObject();
  const { auth } = useSelector((rootState: RootState) => rootState);
  const currentRole = auth.Auth ? auth?.Auth?.role : '';
  const location = useLocation();
  const history = useHistory();
  const [categoryList, setCategoryList] = useState<OptionItems[]>([]);
  const [typeList, setTypeList] = useState<OptionItems[]>([]);
  const [regionList] = useState<OptionItems[]>(
    Object.keys(regionDistrict).map(key => ({
      label: regionDistrict[key].label,
      value: regionDistrict[key].value,
    })),
  );
  const [districtList, setDistrictList] = useState<OptionItems[]>([]);
  const {
    dataList: eventList,
    count,
    page,

    filter,
  } = useTableComponentForListPage<GetEventListParams, Event>({
    call:
      currentRole === AdminRoleEnum.超級管理員 ||
      currentRole === AdminRoleEnum.職員
        ? getEventList
        : getEventForCompanyAdmin,
    filterVariableArray: [
      'rootCategory',
      'category',
      'type',
      'region',
      'district',
      'isRecommended',
      'status',
      'startDate',
      'endDate',
      'asc',
      'desc',
    ],
  });

  const initAllFilterList = useCallback(async () => {
    try {
      const categoryListRes = await getEventCategoryList();
      setCategoryList(
        categoryListRes.map((item: EventCategory) => ({
          label: item.categoryName,
          value: item.categoryName,
        })),
      );
      const typeListRes = await getCarerTypeList();
      setTypeList(
        typeListRes.map((item: string) => ({
          label: item,
          value: item,
        })),
      );
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    initAllFilterList();
  }, [initAllFilterList]);

  useEffect(() => {
    if (filter && filter.region) {
      setDistrictList(regionDistrict[filter.region].options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>中心活動列表 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="中心活動" breadcrumbItem="中心活動列表" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col lg={10}>
                        <Row>
                          <Col lg={3}>
                            <SelectFilter
                              name="rootCategory"
                              label="活動類型"
                              placeholder="活動類型"
                              options={EVENT_ROOTCATEGORY_OPTIONS}
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <SelectFilter
                              name="category"
                              label="服務分類"
                              placeholder="服務分類"
                              options={categoryList}
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <SelectFilter
                              name="type"
                              label="照顧類別"
                              placeholder="照顧類別"
                              options={typeList}
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <SelectFilter
                              name="region"
                              label="分區"
                              placeholder="分區"
                              options={regionList}
                              onChange={(e: any) => {
                                const params = new URLSearchParams(
                                  location.search,
                                );
                                if (e) {
                                  params.set('region', e.value);
                                  setDistrictList(
                                    regionDistrict[e.value].options,
                                  );
                                } else {
                                  params.delete('region');
                                  setDistrictList([]);
                                }
                                params.set('page', '1');
                                params.delete('district');
                                history.push({ search: params.toString() });
                              }}
                              removeOnChangeDefaultBehavior
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <SelectFilter
                              name="district"
                              label="地域"
                              placeholder="地域"
                              options={districtList}
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <SelectFilter
                              name="isRecommended"
                              label="推薦活動"
                              placeholder="推薦活動"
                              options={YES_NO_OPTIONS}
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <SelectFilter
                              name="status"
                              label="狀態"
                              placeholder="狀態"
                              options={EVENT_STATUS_OPTIONS}
                              isClearable
                            />
                          </Col>
                          <Col lg={3}>
                            <DatePickerFilter
                              name="startDate"
                              name2="endDate"
                              label="活動日期範圍"
                              placeholder="活動日期範圍"
                              mode="range"
                              isClearable
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        lg={2}
                        sm={12}
                        className="d-flex flex-row-reverse align-items-center"
                      >
                        <Link
                          to={`/events/items/new`}
                          className="btn btn-primary mb-2"
                        >
                          {` ${'新增中心活動'}`}
                        </Link>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col
                        sm={12}
                        className="d-flex align-self-end justify-content-end"
                      >
                        <TableCount count={count} title="中心活動總數" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={EventListColumns(regionDistrict)}
                            keyField="eventId"
                            data={eventList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
