import { AdminRoleEnum, AdminRoleType } from 'app/models';
import { AdminFormPage, AdminListPage } from 'app/pages/AdminPage';
import { LoginPage, LogoutPage } from 'app/pages/Authentication';
import { BannerFormPage, BannerListPage } from 'app/pages/BannerPage';
import {
  CareerPlanningFormPage,
  CareerPlanningListPage,
} from 'app/pages/CareerPlanningPage';
import { CentreFormPage, CentreListPage } from 'app/pages/CentrePage';
import { CompanyFormPage, CompanyListPage } from 'app/pages/CompanyPage';
import { CompanyDetailPage } from 'app/pages/CompanyPage/CompanyDetailPage';
import { ContactFormPage, ContactListPage } from 'app/pages/ContactPage';
import { EventApplicationFormPage } from 'app/pages/EventApplicationPage';
import { EventApplicationListPage } from 'app/pages/EventApplicationPage/EventApplicationListPage';
import { EventFormPage } from 'app/pages/EventPage';
import { EventDetailPage } from 'app/pages/EventPage/EventDetailPage';
import { EventListPage } from 'app/pages/EventPage/EventListPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { JobApplicationListPage } from 'app/pages/JobApplicationPage/JobApplicationListPage';
import { JobDetailPage, JobFormPage, JobListPage } from 'app/pages/JobPage';
import { NewsFormPage, NewsListPage } from 'app/pages/NewsPage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import {
  NotificationFormPage,
  NotificationListPage,
} from 'app/pages/NotificationPage';
import { ResumeDetailPage } from 'app/pages/ResumePage';
import {
  CentreCategorySortListPage,
  NewsCategorySortListPage,
  UsefulInfoCategorySortListPage,
} from 'app/pages/SortListPage';
import { BannerSortListPage } from 'app/pages/SortListPage/BannerSortListPage/Loadable';
import { EventCategorySortListPage } from 'app/pages/SortListPage/EventCategorySortListPage/Loadable';
import { TakeDownPage } from 'app/pages/TakeDownFormPage';
import { TakeDownDetailPage, TakeDownListPage } from 'app/pages/TakeDownPage';
import {
  UsefulInfoFormPage,
  UsefulInfoListPage,
} from 'app/pages/UsefulInfoPage';
import { UserFormPage, UserListPage } from 'app/pages/UserPage';
import { UserDetailPage } from 'app/pages/UserPage/UserDetailPage';
import { Redirect } from 'react-router-dom';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
  permissions?: AdminRoleType[];
}
const userRoutes: Route[] = [
  //Banner
  {
    path: '/banners',
    component: BannerListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/banners/new',
    component: BannerFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/banners/edit/:bannerId',
    component: BannerFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  //Event
  {
    path: '/events/items',
    component: EventListPage,
  },
  {
    path: '/events/items/new',
    component: EventFormPage,
    exact: true,
  },
  {
    path: '/events/items/edit/:eventId',
    component: EventFormPage,
    exact: true,
  },
  {
    path: '/events/items/detail/:eventId',
    component: EventDetailPage,
    exact: true,
  },
  {
    path: '/events/applications',
    component: EventApplicationListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/events/applications/edit/:eventApplicationId',
    component: EventApplicationFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  //Centre
  {
    path: '/centres',
    component: CentreListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/centres/new',
    component: CentreFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/centres/edit/:centreId',
    component: CentreFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  //News
  {
    path: '/news',
    component: NewsListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/news/new',
    component: NewsFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/news/edit/:newsId',
    component: NewsFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  // UsefulInfo
  {
    path: '/usefulInfo',
    component: UsefulInfoListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/usefulInfo/new',
    component: UsefulInfoFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/usefulInfo/edit/:usefulInfoId',
    component: UsefulInfoFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },

  // CareerPlanning
  {
    path: '/careerPlannings',
    component: CareerPlanningListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/careerPlannings/new',
    component: CareerPlanningFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/careerPlannings/edit/:usefulInfoId',
    component: CareerPlanningFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  //Contact
  {
    path: '/contacts',
    component: ContactListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/contacts/new',
    component: ContactFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/contacts/edit/:contactId',
    component: ContactFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  //Scheduled Notification
  {
    path: '/notifications',
    component: NotificationListPage,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/notifications/new',
    component: NotificationFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  //Sorting
  {
    path: '/sorting/eventCategories',
    component: EventCategorySortListPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/sorting/newsCategories',
    component: NewsCategorySortListPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/sorting/centreCategories',
    component: CentreCategorySortListPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/sorting/usefulInfoCategories',
    component: UsefulInfoCategorySortListPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/sorting/banners',
    component: BannerSortListPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  //User
  {
    path: '/users',
    component: UserListPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/users/edit/:userId',
    component: UserFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/users/detail/:userId',
    component: UserDetailPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員, AdminRoleEnum.職員],
  },
  {
    path: '/users/resumes/:resumeId',
    exact: true,
    component: ResumeDetailPage,
  },
  // Admin
  {
    path: '/admins',
    component: AdminListPage,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/admins/new',
    component: AdminFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/admins/edit/:adminId',
    component: AdminFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/admins/edit/:adminId/:password',
    component: AdminFormPage,
    exact: true,
    permissions: [AdminRoleEnum.超級管理員],
  },
  // Company
  {
    path: '/companies',
    component: CompanyListPage,
  },
  {
    path: '/companies/new',
    component: CompanyFormPage,
    exact: true,
  },
  {
    path: '/companies/edit/:companyId',
    component: CompanyFormPage,
    exact: true,
  },
  {
    path: '/companies/detail/:companyId',
    component: CompanyDetailPage,
    exact: true,
  },
  // Job
  {
    path: '/jobs/items',
    component: JobListPage,
    exact: true,
  },
  {
    path: '/jobs/items/new',
    component: JobFormPage,
    exact: true,
  },
  {
    path: '/jobs/items/edit/:jobId',
    component: JobFormPage,
    exact: true,
  },
  {
    path: '/jobs/items/detail/:jobId',
    component: JobDetailPage,
    exact: true,
  },
  {
    path: '/jobs/applications',
    component: JobApplicationListPage,
    exact: true,
  },
  // takedown
  {
    path: '/take-down',
    component: TakeDownListPage,
    permissions: [AdminRoleEnum.超級管理員],
  },
  {
    path: '/take-down/detail/:takeDownId',
    component: TakeDownDetailPage,
    permissions: [AdminRoleEnum.超級管理員],
  },
  { path: '/logout', component: LogoutPage },
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
];

const authRoutes: Route[] = [
  { path: '/takedown', component: TakeDownPage },
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage, exact: true },
  { path: '/', component: LoginPage, exact: true },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

export { userRoutes, authRoutes };
