import { OptionItems } from 'app/models';
import { FieldHookConfig } from 'formik';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from 'reactstrap';
import './autoCompleteSelect.scss';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  name: string;
  label: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  options: OptionItems[];
  inputValue: string;
  onInputChange: (e) => void;
  onMenuScrollToBottom?: () => void;
  onChange?: (value: OptionItems) => void;
  noOptionText?: string;
  customValue?: string;
  getOneData?: (id: number) => Promise<any>;
}

const AutoCompleteSelect = (
  props: OtherProps & FieldHookConfig<string | number>,
) => {
  const [menu, setMenu] = useState(false);
  const [inputText, setInputText] = useState<string>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [cacheOptions, setCacheOption] = useState<OptionItems[]>([]);
  const [value, setValue] = useState<OptionItems | null>(null);
  const [data, setData] = useState<any>();

  const history = useHistory();
  const location = useLocation();

  const {
    name,
    label,
    placeholder,
    disabled,
    options,
    onChange,
    onInputChange,
    onMenuScrollToBottom,
    noOptionText,
    getOneData,
  } = props;

  const filteredOptions = cacheOptions.filter(option =>
    option.label.toLowerCase().includes(inputText.toLowerCase()),
  );

  const bottomReached = () => {
    onMenuScrollToBottom && onMenuScrollToBottom();
  };

  const scrollRef: RefObject<HTMLDivElement> = useBottomScrollListener(
    bottomReached,
  );

  const closeBtn = () => {
    setValue({ label: '', value: '' });
    const params = new URLSearchParams(location.search);
    params.delete(name);
    params.delete(`${name}Label`);
    history.push({ search: params.toString() });
    setIsFocus(false);
  };

  useEffect(() => {
    const tempList = [...cacheOptions, ...options];
    const unique = Array.from(
      new Map(tempList.map(obj => [JSON.stringify(obj), obj])).values(),
    );
    if (unique.length !== cacheOptions.length) {
      setCacheOption(unique);
    }
  }, [options, cacheOptions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    if (key && data) {
      if (data.name) {
        setValue({
          value: `${data.companyId}`,
          label: `#${key} | ${data.name}`,
        });
      } else if (data.title) {
        setValue({
          value: `${data.companyId}`,
          label: `#${key} | ${data.title}`,
        });
      } else if (data.displayName) {
        setValue({
          value: `${data.companyId}`,
          label: `#${key} | ${data.displayName} | ${data.phoneNumber}`,
        });
      }
    } else {
      setValue(null);
    }
  }, [location.search, name, options, data]);

  const getComData = useCallback(async () => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    if (key) {
      const dataRes = await getOneData?.(parseInt(key));
      setData(dataRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    getComData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <div className="auto-complete-select-container">
      <Dropdown
        className={`auto-complete form-group mb-2`}
        toggle={() => {
          if (isFocus) {
            setMenu(true);
          } else {
            setMenu(!menu);
          }
        }}
        isOpen={menu}
        disabled={disabled}
      >
        {label ? <Label>{props.label}</Label> : null}
        <DropdownToggle tag="div" className="dropdown-toggle-div d-flex">
          {/* input field */}
          <Input
            className="form-control"
            placeholder={placeholder}
            type="text"
            autoComplete="new-password"
            disabled={disabled}
            value={isFocus ? inputText : value?.label}
            onChange={e => {
              setInputText(e.target.value);
              setIsFocus(true);
              if (onInputChange) onInputChange(e.target.value);
            }}
            onFocus={() => setIsFocus(true)}
            onBlur={() => {
              setIsFocus(false);
            }}
          />
          {value && (
            <div className="cross-btn-div" onClick={closeBtn}>
              <i className="mdi mdi-close"></i>
            </div>
          )}
          {!value && (
            <div className="arrow-down-btn-div">
              <i className="bx bx-chevron-down"></i>
            </div>
          )}
        </DropdownToggle>

        {/* drop down */}
        <DropdownMenu className="dropdown-menu-start w-100">
          <div className="drop-down-card drop-down-menu" ref={scrollRef}>
            <div className="drop-down-card-body">
              {filteredOptions.map(option => (
                <div
                  className={`drop-down-option-item`}
                  key={option.value}
                  onClick={() => {
                    if (onChange) {
                      onChange(option);
                    } else {
                      const params = new URLSearchParams(location.search);
                      if (option.value) {
                        params.set(name, option.value);
                      } else {
                        params.delete(name);
                      }
                      params.set('page', '1');
                      history.push({ search: params.toString() });
                    }
                    setMenu(false);
                    setInputText('');
                  }}
                >
                  {option.label}
                </div>
              ))}
              {filteredOptions?.length === 0 && (
                <div className="no-option-text">
                  {noOptionText || '沒有選項'}{' '}
                </div>
              )}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default AutoCompleteSelect;
