import { CompanyResonse, TimeStamp, User } from './';

export enum JobStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface JobParams {
  employmentType: string;
  industry: string;
  payrollPeriod: string;
  salary: string;
  title: string;
  description: string;
  vacancy: string | number;
  postedAt: string;
  endedAt: string;
  region: string;
  districts: string[];
  address: string;
  contactPerson: string;
  contactPhone: string;
  referenceCode: string;
  measures: string;
  workingDays: string;
  workingHours: string;
  requirements: string;
  others: string;
  isResumeFirst: boolean;
  companyId: number;
  status: JobStatus;
}
export interface Job extends JobParams, TimeStamp {
  Company: CompanyResonse;
  jobId: number;
}

export enum EmploymentType {
  'Full-Time' = '全職',
  'Part-Time' = '兼職',
  Contract = '合約工',
  Internship = '實習',
  Volunteer = '義工',
  Temporary = '臨時工',
}

export enum PayrollPeriod {
  Hourly = '時薪',
  Daily = '日薪',
  Weekly = '週薪',
  Monthly = '月薪',
}
export enum JobApplicationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface JobApplication extends TimeStamp {
  jobApplicationId: number;
  resumeId: number;
  userId: number;
  jobId: number;
  status: JobApplicationStatus;
  User: User;
  Job: Job;
}
