import { EventStatusMap } from 'app/constants';
import { getImageURL } from 'app/helpers/CommonHelper';
import { Event, ListColumn } from 'app/models/';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import './event.scss';

const EventListColumns: (item) => ListColumn<Event>[] = regionDistrictMap => [
  {
    text: '',
    dataField: 'img',
    canSort: false,
    formatter: (event: Event) => (
      <>
        {!event.images ? (
          <div className="avatar-lg">
            <h1 className="w-100 h-100 avatar-videoUrl rounded-circle bg-light text-secondary d-flex flex-row justify-content-center align-items-center">
              <i className="bx bx-calendar-event" />
            </h1>
          </div>
        ) : (
          <img
            className="avatar-lg rounded-circle "
            src={getImageURL(event.images[0])}
            alt="cover"
            style={{ objectFit: 'cover' }}
          />
        )}
      </>
    ),
  },
  {
    text: '編號',
    dataField: 'eventId',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">#{event.eventId}</div>
        </h5>
      </>
    ),
  },
  {
    text: '活動名稱',
    dataField: 'title',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1">{event.title ? event.title : '-'}</h5>
      </>
    ),
  },
  {
    text: '活動類型',
    dataField: 'rootCategory',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <div className="font-size-14 mb-1">
          <Badge
            pill
            className={`badge-soft-${
              event.rootCategory === '本中心活動' ? 'success' : 'dark'
            } ms-1 p-2 font-size-12`}
          >
            {event.rootCategory}
          </Badge>
          {event.Company ? (
            <h6 className="mt-1 ps-1 font-size-12 fw-bold vertical-align-start">
              所屬機構：
              <Link to={`/companies/detail/${event.companyId}`}>
                {event.Company.name}
              </Link>
            </h6>
          ) : null}
        </div>
      </>
    ),
  },
  {
    text: '服務分類',
    dataField: 'categoryName',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Badge pill className="badge-soft-primary ms-1 p-2 font-size-12">
            {event.categoryName ? event.categoryName : '-'}
          </Badge>
        </h5>
      </>
    ),
  },
  {
    text: '照顧類別',
    dataField: 'types',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1">
          {event.types?.map((type: string, index: number) => (
            <React.Fragment key={`type-${index}`}>
              <Badge
                key={type}
                pill
                className="badge-soft-warning ms-1 mb-1 p-2 font-size-12"
              >
                {type ? type : '-'}
              </Badge>
              {index % 3 === 2 ? <br /> : ''}
            </React.Fragment>
          ))}
        </h5>
      </>
    ),
  },
  {
    text: '分域',
    dataField: '',
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1">
          {event.region
            ? `${
                regionDistrictMap[event.region]
                  ? regionDistrictMap[event.region].label
                  : ''
              } ${
                event.district
                  ? regionDistrictMap[event.region]
                    ? regionDistrictMap[event.region].map[event.district]
                    : ''
                  : ''
              }`
            : '-'}
        </h5>
      </>
    ),
  },
  {
    text: '聯絡人',
    dataField: '',
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-12 fw-bold mb-1">
          {event.contactPerson ? `名稱: ${event.contactPerson}` : ''}
        </h5>
        <h5 className="font-size-12 fw-bold mb-1">
          {event.contactPhone ? `聯絡人電話: ${event.contactPhone}` : ''}
        </h5>
      </>
    ),
  },
  {
    text: '推薦活動',
    dataField: '',
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-12 fw-bold mb-1">
          <i
            style={{
              color: event.isRecommended ? '#34c38f' : '#f46a6a',
            }}
            className={`${
              event.isRecommended ? 'bx bx-check-circle ' : 'bx bx-x-circle'
            } fs-4 `}
          />
        </h5>
      </>
    ),
  },
  {
    text: '結束報名日期',
    dataField: 'endedAt',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(event.endedAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(event.createdAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '狀態',
    dataField: 'status',
    canSort: true,
    formatter: (event: Event) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">
            {event?.status ? (
              <Badge
                pill
                className={`badge-soft-${
                  EventStatusMap.get(event.status)?.colorType
                } ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
              >
                {EventStatusMap.get(event.status)?.label}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    text: '',
    formatter: (event: Event) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <Link
            to={`/events/items/new?copyEventId=${event?.eventId}`}
            id={'new' + event.eventId}
          >
            <i className="mdi mdi-content-copy" />
            <UncontrolledTooltip placement="top" target={'new' + event.eventId}>
              複製中心活動
            </UncontrolledTooltip>
          </Link>
        </li>
        <li className="list-inline-item px-2">
          <Link
            to={`/events/items/detail/${event.eventId}`}
            id={'detail' + event.eventId}
          >
            <i className="bx bx-detail" />
            <UncontrolledTooltip
              placement="top"
              target={'detail' + event.eventId}
            >
              檢視中心活動
            </UncontrolledTooltip>
          </Link>
        </li>
        <li className="list-inline-item px-2">
          <Link
            to={`/events/items/edit/${event.eventId}`}
            id={'edit' + event.eventId}
          >
            <i className="bx bx-edit-alt" />
            <UncontrolledTooltip
              placement="top"
              target={'edit' + event.eventId}
            >
              編輯中心活動
            </UncontrolledTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default EventListColumns;
