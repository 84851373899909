import { TimeStamp } from './Common';
import { CompanyResonse } from './Company';

export type AdminRoleType = 'admin' | 'staff' | 'company';

export enum AdminRoleEnum {
  '超級管理員' = 'admin',
  '職員' = 'staff',
  '公司或機構管理員' = 'company',
}
export interface AdminRoleInfo {
  label: string;
  value: string;
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
}
export interface AdminLoginPayload {
  loginName: string;
  password: string;
}
export interface AdminAuth extends TimeStamp {
  adminAuthId: number;
  adminId: number;
  provider: string;
  loginName: string | null;
  email: string | null;
  hash: string | null;
  uid: string | null;
  phoneNumber: string | null;
}
export interface Admin extends TimeStamp {
  adminId: number;
  avatar: string;
  email: string | null;
  hash: string | null;
  loginName: string;
  displayName: string;
  role: AdminRoleEnum;
  Auths: AdminAuth;
  Company?: CompanyResonse;
  companyId: number;
}

export interface AdminParam {
  displayName: string;
  loginName?: string;
  password?: string;
}
