/**
 *
 * Asynchronously loads the component for JobListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const JobListPage = lazyLoad(
  () => import('./index'),
  module => module.JobListPage,
);
