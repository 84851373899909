/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import NonAuthLayout from 'app/components/NonAuthLayout';
// layouts Format
import VerticalLayout from 'app/components/VerticalLayout';
import { TOAST_TIME } from 'app/config';
import { authRoutes, userRoutes } from 'app/route/allRoutes';
import ProtectedRoute from 'app/route/ProtectedRoute';
import { AxiosInterceptor } from 'app/services';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store';
import { GlobalStyle } from 'styles/global-styles';
import { setRegionDistrictInLocalStorage } from './helpers/CommonHelper';

export function App() {
  // const dispatch = useDispatch();
  const { layout, auth } = useSelector((rootState: RootState) => rootState);

  const { layoutType } = layout;
  const { Auth } = auth;
  const { i18n } = useTranslation();
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = getLayout();

  useEffect(() => {
    // dispatch(getCurrentProfile());
    setRegionDistrictInLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <MetaTags
        titleTemplate="Admin Panel"
        defaultTitle="Admin Panel"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Admin Panel" />
      </MetaTags>
      <AxiosInterceptor>
        <Switch>
          {Auth &&
            userRoutes.map((route, idx) => (
              <ProtectedRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          {authRoutes.map((route, idx) => (
            <ProtectedRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact={route.exact}
            />
          ))}
          <Redirect from="*" to="/notFound" />
        </Switch>
      </AxiosInterceptor>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_TIME}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyle />
    </BrowserRouter>
  );
}
