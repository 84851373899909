import clsx from 'clsx';
import 'flatpickr/dist/themes/material_blue.css';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useHistory, useLocation } from 'react-router-dom';
import { InputGroup, Label } from 'reactstrap';
import './filter.scss';

interface Props {
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  onChange?: (e) => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
  name2?: string;
  minDate?: Date | Date[] | null;
  mode?: 'single' | 'multiple' | 'range';
}

const DatePickerFilter = ({
  label,
  placeholder,
  onChange,
  name,
  name2,
  minDate,
  isClearable,
  mode,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState<Date | Date[] | null>(null);
  const ref = useRef(null);
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    if (mode === 'range') {
      const key2 = query.get(name2!);
      if (key && key2) {
        setValue([moment(key).toDate(), moment(key2).toDate()]);
      } else {
        setValue(null);
      }
    } else {
      if (key) {
        setValue(moment(key).toDate());
      } else {
        setValue(null);
      }
    }
  }, [location.search, name, mode, name2]);

  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <InputGroup style={{ position: 'relative' }}>
        <Flatpickr
          className="form-control d-block bg-white input-border date-picker-input"
          placeholder={placeholder || '日期'}
          options={{
            mode: mode ? mode : 'single',
            altInput: true,
            altFormat: 'd-m-Y',
            dateFormat: 'd-m-Y',
            minDate: minDate ? minDate[0] : null,
          }}
          ref={ref}
          value={value}
          onChange={date => {
            const params = new URLSearchParams(location.search);
            if (mode === 'range') {
              if (date && date.length === 2) {
                params.set(name, moment(date[0]).startOf('day').toISOString());
                params.set(name2!, moment(date[1]).endOf('day').toISOString());
              } else {
                params.delete(name);
                params.delete(name2!);
              }
            } else {
              if (date && date[0]) {
                params.set(name, moment(date[0]).toISOString());
              } else {
                params.delete(name);
              }
            }
            params.set('page', '1');
            history.replace({ search: params.toString() });
            if (onChange) {
              onChange(date);
            }
          }}
        />
        {isClearable ? (
          <i
            className="bx bx-x-circle font-size-16 date-picker-clear-icon"
            onClick={() => {
              const params = new URLSearchParams(location.search);
              params.delete(name);
              if (name2) {
                params.delete(name2);
              }
              for (const [key, value] of Array.from(params.entries()).filter(
                ([key]) => key !== name && key !== name2,
              )) {
                params.set(key, value);
              }

              history.push({ search: params.toString() });
            }}
          />
        ) : null}
      </InputGroup>
    </div>
  );
};

export default DatePickerFilter;
