import React from 'react';
import { Modal } from 'reactstrap';

interface Props {
  visible: boolean;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  children: React.ReactChild;
}

function Dialog({
  visible,
  title,
  onClose,
  loading,
  onConfirm,
  children,
}: Props) {
  return (
    <Modal isOpen={visible} scrollable={true}>
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          disabled={loading}
        ></button>
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={onClose}
          disabled={loading}
        >
          取消
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={onConfirm}
          disabled={loading}
        >
          {loading ? <i className="bx bx-loader-circle bx-spin" /> : '確定'}
        </button>
      </div>
    </Modal>
  );
}

export default Dialog;
