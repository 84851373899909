import {
  Event,
  EventApplication,
  EventCategory,
  Filter,
  ListResult,
} from 'app/models';
import Axios from '.';

/**
 * Get Event list (https://jcabc-app-dev.appicidea.com/doc/#/Event/EventController_listEvent)
 */

export interface GetEventListParams extends Filter {
  title?: string;
  region?: string;
  district?: string;
  rootCategory?: string;
  category?: string;
  type?: string;
  isRecommended?: boolean;
  status?: string;
  startDate?: string;
  endDate?: string;
  companyId?: string;
}

export const getEventList = async (params: GetEventListParams) => {
  const { data } = await Axios.get<ListResult<Event>>('/events', {
    params,
  });
  return data;
};

/**
 * Create new Event  (https://jcabc-app-dev.appicidea.com/doc/#/Event/EventController_createEvent)
 */

export interface CreateNewEventParams {
  rootCategory: string | null;
  companyId: string | number | null;
  categoryName: string | null;
  types: string[];
  title: string;
  endedAt: string | null;
  description: string;
  lat: number | null;
  lng: number | null;
  dates: string[];
  startTime: string | null;
  endTime: string | null;
  region: string;
  district: string;
  address: string | null;
  target: string | null;
  quota: number;
  price: number;
  url: string | null;
  images: string[];
  contactPerson: string | null;
  contactPhone: string | null;
  isRecommended: boolean;
  status?: string;
}

export const createNewEvent = async (params: CreateNewEventParams) => {
  const { data } = await Axios.post<Event>(`/events`, params);
  return data;
};

/**
 * Get one Event (https://jcabc-app-dev.appicidea.com/doc/#/Event/EventController_getEventById)
 */

export const getOneEvent = async (eventId: number | string) => {
  const { data } = await Axios.get<Event>(`/events/${eventId}`);
  return data;
};

/**
 * Update one Event (https://jcabc-app-dev.appicidea.com/doc/#/Event/EventController_updateEventById)
 */
export const updateOneEvent = async (
  eventId: number,
  params: Partial<CreateNewEventParams>,
) => {
  const { data } = await Axios.patch<string>(`/events/${eventId}`, params);
  return data;
};

/**
 * Delete one Event(https://jcabc-app-dev.appicidea.com/doc/#/Event/EventController_deleteEventById)
 */

export const deleteOneEvent = async (eventId: number) => {
  const { data } = await Axios.delete<string>(`/events/${eventId}`);
  return data;
};

/**
 * Get Event Category list (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Category/EventCategoryController_listCategories)
 */
export const getEventCategoryList = async () => {
  const { data } = await Axios.get<EventCategory[]>('/eventCategories', {});
  return data;
};

/**
 * Update Event Category list (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Category/EventCategoryController_updateCategoryOrder)
 */

export interface UpdateEventCategoryListParams {
  categoryName: string;
  ordering: number;
}

export const updateEventCategoryList = async (
  params: UpdateEventCategoryListParams[],
) => {
  const { data } = await Axios.put<EventCategory[]>('/eventCategories', params);
  return data;
};

/**
 *  Get All Event Application  (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Application/EventApplicationController_listEventApplications)
 */

export interface GetEventApplicationsListParams extends Filter {
  userId?: string;
  eventId?: string;
  status?: string;
  preferredType?: string;
  assignedType?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export const getEventApplicationsList = async (
  params: GetEventApplicationsListParams,
) => {
  const { data } = await Axios.get<ListResult<EventApplication>>(
    `/eventApplications`,
    {
      params,
    },
  );
  return data;
};

/**
 *  Create a Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Application/EventApplicationController_updateEventApplication)
 */
export interface CreateNewEventApplicationParams {
  userId: number;
  eventId: number;
  name: string;
  phone: string;
  status: string;
}
export const createNewEventApplication = async (
  params: CreateNewEventApplicationParams,
) => {
  const { data } = await Axios.post<EventApplication>('/events/attend', params);
  return data;
};

/**
 *  Get One Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Application/EventApplicationController_getEventApplication)
 */

export const getOneEventApplication = async (eventApplicationId: string) => {
  const { data } = await Axios.get<EventApplication>(
    `/eventApplications/${eventApplicationId}`,
  );
  return data;
};

/**
 *  Update One Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Application/EventApplicationController_updateEventApplication)
 */

export const updateOneEventApplication = async (
  eventApplicationId: string,
  params: Partial<CreateNewEventApplicationParams>,
) => {
  const { data } = await Axios.patch<null>(
    `/eventApplications/${eventApplicationId}`,
    params,
  );
  return data;
};

/**
 *  Delete one Event Application work (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Application/EventApplicationController_deleteEventApplication)
 */
export const deleteOneEventApplication = async (eventApplicationId: number) => {
  const { data } = await Axios.delete<string>(
    `/eventApplications/${eventApplicationId}`,
  );
  return data;
};

/**
 * Batch Update record (https://jcabc-app-dev.appicidea.com/doc/#/Event%20Application/EventApplicationController_batchUpdateEventApplication)
 */

export interface BatchUpdateEventApplicationParams {
  eventApplicationId: number;
  status: string;
}

export const batchUpdateEventApplication = async (
  params: BatchUpdateEventApplicationParams[],
) => {
  const { data } = await Axios.patch<any>(`/eventApplications/batch`, params);
  return data;
};
