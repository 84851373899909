export const USEFULINFO_ROOTCATEGORY_OPTIONS = [
  {
    label: '照顧者照顧者第二人生故事',
    value: '照顧者照顧者第二人生故事',
  },
  { label: '照顧秘笈', value: '照顧秘笈' },
];

export const USEFULINFO_ROOTCATEGORY_OPTION1_CATEGORY_OPTIONS = [
  {
    label: '照顧者照顧者第二人生故事',
    value: '照顧者照顧者第二人生故事',
  },
];

export const USEFULINFO_ROOTCATEGORY_OPTION2_CATEGORY_OPTIONS = [
  {
    label: '衣',
    value: '衣',
  },
  { label: '食', value: '食' },
  { label: '住', value: '住' },
  { label: '行', value: '行' },
];
