import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Switch from 'react-switch';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      否
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      是
    </div>
  );
};

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
}

const SwitchField = (props: OtherProps & FieldHookConfig<boolean>) => {
  const [field, meta, helpers] = useField(props);
  const { label, horizontal } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={
        horizontal
          ? { display: 'flex', flexDirection: 'row', alignItems: 'center' }
          : {}
      }
    >
      {label ? (
        <div
          style={{
            margin: '8px 8px 8px 0px',
            fontFamily: 'Georgia,Times,Times New Roman,serif',
          }}
        >
          {label}
        </div>
      ) : null}
      <div className="square-switch">
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          onColor="#f8b425"
          onChange={e => {
            helpers.setValue(e);
          }}
          checked={field.value}
        />
      </div>
    </div>
  );
};

export default SwitchField;
