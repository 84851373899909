import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import Dialog from 'app/components/Modal/Modal';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME, SUBPAGE_PAGE_SIZE } from 'app/config';
import {
  EVENT_STATUS_OPTIONS,
  JOB_APPLICATION_STATUS_OPTIONS,
} from 'app/constants';
import { YES_NO_OPTIONS } from 'app/constants/Common';
import { getImageURL, getRegionDistrictObject } from 'app/helpers/CommonHelper';
import { useAutoComplete } from 'app/hooks';
import {
  AdminRoleEnum,
  CompanyResonse,
  EmploymentType,
  Event,
  EventCategory,
  Job,
  JobApplication,
  OptionItems,
  User,
} from 'app/models';
import EventListColumns from 'app/pages/EventPage/EventListPage/EventListColumns';
import JobApplicationListColumns from 'app/pages/JobApplicationPage/JobApplicationListPage/JobApplicationListColumns';
import JobListColumns from 'app/pages/JobPage/JobListPage/JobListColumns';
import {
  getEventForCompanyAdmin,
  getJobApplicationsForCompanyAdmin,
  getJobForCompanyAdmin,
} from 'app/services/AdminServices';
import { getOneCompany } from 'app/services/CompanyServices';
import {
  getEventCategoryList,
  getEventList,
  GetEventListParams,
} from 'app/services/EventService';
import {
  getJobApplicationsList,
  getJobListData,
  GetJobListParams,
  getOneJob,
  updateOneJobApplication,
} from 'app/services/JobService';
import {
  getOneUser,
  getUserList,
  GetUserListParams,
} from 'app/services/UserService';
import { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table as ReactstrapTable,
  TabPane,
} from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';

interface FormQuery {
  companyId: string;
}

export const CompanyDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const params = useParams<FormQuery>();
  const { auth } = useSelector((rootState: RootState) => rootState);
  const regionDistrict = getRegionDistrictObject();
  const [regionList] = useState<OptionItems[]>(
    Object.keys(regionDistrict).map(key => ({
      label: regionDistrict[key].label,
      value: regionDistrict[key].value,
    })),
  );
  const [districtList, setDistrictList] = useState<OptionItems[]>([]);
  const currentRole = auth.Auth ? auth?.Auth?.role : '';
  const [company, setCompany] = useState<CompanyResonse>();
  const [companyId, setCompanyId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  //Tab variables
  const [activeTabNum, setActiveTabNum] = useState<string>('1');
  //State for tab 1
  const [companyJobList, setCompanyJobList] = useState<Job[]>([]);
  const [companyJobListPage, setCompanyJobListPage] = useState<number>(1);
  const [companyJobListCount, setCompanyJobListCount] = useState<number>(0);
  const [companyJobApplicationList, setCompanyJobApplicationList] = useState<
    JobApplication[]
  >([]);
  const [
    companyJobApplicationListPage,
    setCompanyJobApplicationListPage,
  ] = useState<number>(1);
  const [
    companyJobApplicationListCount,
    setCompanyJobApplicationListCount,
  ] = useState<number>(0);
  const [changeStatusVisible, setChangeStatusVisible] = useState<boolean>(
    false,
  );
  const [clickedJobApplicationId, setClickedJobApplicationId] = useState<
    number
  >(0);
  const [clickedJobStatus, setClickedJobStatus] = useState<string>('');
  const [companyEventList, setCompanyEventList] = useState<Event[]>([]);
  const [companyEventListPage, setCompanyEventListPage] = useState<number>(1);
  const [companyEventListCount, setCompanyEventListCount] = useState<number>(0);
  const [categoryList, setCategoryList] = useState<OptionItems[]>([]);
  const initPage = useCallback(async () => {
    dispatch(changePreloader(true));
    const { companyId: _companyId } = params;
    try {
      if (_companyId) {
        const companyRes = await getOneCompany(parseInt(_companyId));
        setCompanyId(parseInt(_companyId));
        setCompany(companyRes);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initPage();
  }, [initPage]);

  const {
    searchText: userText,
    setSearchText: setUserText,
    dataList: userList,
    onMenuScrollToBottom: userScrollToBottom,
    updateDataListWithItemCall: updateUserListWithItemCall,
  } = useAutoComplete<GetUserListParams, User>({
    getItemCall: getOneUser,
    getListCall: getUserList,
    labelRender: (item: User) => ({
      label: `#${item.userId} | ${item.displayName} | ${item.phoneNumber}`,
      value: item.userId.toString(),
    }),
    keyExtracter: (item: User) => item.userId,
    searchTextFieldName: 'q',
    preventFirstLoad: currentRole === AdminRoleEnum.公司或機構管理員,
  });

  const {
    searchText: jobText,
    setSearchText: setJobText,
    dataList: jobList,
    onMenuScrollToBottom: onJobMenuScrollToBottom,
    updateDataListWithItemCall: updateJobListWithItemCall,
  } = useAutoComplete<GetJobListParams, Job>({
    getItemCall: getOneJob,
    getListCall:
      currentRole === AdminRoleEnum.超級管理員 ||
      currentRole === AdminRoleEnum.職員
        ? getJobListData
        : getJobForCompanyAdmin,
    labelRender: (item: Job) => ({
      label: `#${item.jobId} | ${item.title} `,
      value: item.jobId.toString(),
    }),
    keyExtracter: (item: Job) => item.jobId,
    searchTextFieldName: 'title',
    initialParams: {
      companyId: params?.companyId ? params?.companyId : undefined,
    },
  });

  // Function in Tab
  const getJobRecords = useCallback(async () => {
    try {
      if (companyId && activeTabNum === '1') {
        const params = new URLSearchParams(location.search);
        const userId = params.get('userId');
        const asc = params.get('asc');
        const desc = params.get('desc');
        const region = params.get('region');
        const district = params.get('district');
        const employmentType = params.get('employmentType');
        const filter = {
          limit: SUBPAGE_PAGE_SIZE,
          offset: (companyJobListPage - 1) * SUBPAGE_PAGE_SIZE,
          asc: asc ? asc : undefined,
          desc: desc ? desc : undefined,
          userId: userId ? userId : undefined,
          district: district ? district : undefined,
          region: region ? region : undefined,
          employmentType: employmentType ? employmentType : undefined,
          companyId: companyId.toString(),
        };
        let applicationRes;
        if (
          currentRole === AdminRoleEnum.超級管理員 ||
          currentRole === AdminRoleEnum.職員
        ) {
          applicationRes = await getJobListData(filter);
        } else {
          applicationRes = await getJobForCompanyAdmin(filter);
        }
        setCompanyJobList(applicationRes.rows);
        setCompanyJobListCount(applicationRes.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    companyId,
    activeTabNum,
    location.search,
    currentRole,
    companyJobListPage,
  ]);

  useEffect(() => {
    getJobRecords();
  }, [getJobRecords]);

  const getJobApplicationRecords = useCallback(async () => {
    try {
      if (companyId && activeTabNum === '2') {
        const params = new URLSearchParams(location.search);
        const userId = params.get('userId');
        const asc = params.get('asc');
        const desc = params.get('desc');
        const status = params.get('status');
        const jobId = params.get('jobId');
        const filter = {
          limit: SUBPAGE_PAGE_SIZE,
          offset: (companyJobApplicationListPage - 1) * SUBPAGE_PAGE_SIZE,
          userId: userId ? userId : undefined,
          jobId: jobId ? jobId.toString() : undefined,
          asc: asc ? asc : undefined,
          desc: desc ? desc : undefined,
          status: status ? status : undefined,
          companyId: companyId.toString(),
        };
        let applicationRes;
        if (
          currentRole === AdminRoleEnum.超級管理員 ||
          currentRole === AdminRoleEnum.職員
        ) {
          applicationRes = await getJobApplicationsList(filter);
        } else {
          applicationRes = await getJobApplicationsForCompanyAdmin(filter);
        }
        setCompanyJobApplicationList(applicationRes.rows);
        setCompanyJobApplicationListCount(applicationRes.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    companyId,
    activeTabNum,
    location.search,
    companyJobApplicationListPage,
    currentRole,
  ]);
  useEffect(() => {
    getJobApplicationRecords();
  }, [getJobApplicationRecords]);

  const getEventRecords = useCallback(async () => {
    try {
      if (companyId && activeTabNum === '3') {
        const params = new URLSearchParams(location.search);
        const asc = params.get('asc');
        const desc = params.get('desc');
        const category = params.get('category');
        const region = params.get('region');
        const district = params.get('district');
        const isRecommended = params.get('isRecommended');
        const status = params.get('status');
        const startDate = params.get('startDate');
        const endDate = params.get('endDate');

        const filter: GetEventListParams = {
          limit: SUBPAGE_PAGE_SIZE,
          offset: (companyEventListPage - 1) * SUBPAGE_PAGE_SIZE,
          asc: asc ? asc : undefined,
          desc: desc ? desc : undefined,
          category: category ? category : undefined,
          district: district ? district : undefined,
          region: region ? region : undefined,
          isRecommended: isRecommended ? isRecommended === 'true' : undefined,
          status: status ? status : undefined,
          startDate: startDate ? startDate : undefined,
          endDate: endDate ? endDate : undefined,
          companyId: companyId.toString(),
        };
        let applicationRes;
        if (
          currentRole === AdminRoleEnum.超級管理員 ||
          currentRole === AdminRoleEnum.職員
        ) {
          applicationRes = await getEventList(filter);
        } else {
          applicationRes = await getEventForCompanyAdmin(filter);
        }
        setCompanyEventList(applicationRes.rows);
        setCompanyEventListCount(applicationRes.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    companyId,
    activeTabNum,
    location.search,
    companyEventListPage,
    currentRole,
  ]);
  useEffect(() => {
    getEventRecords();
  }, [getEventRecords]);

  const initEventCategoryList = useCallback(async () => {
    try {
      const categoryListRes = await getEventCategoryList();

      setCategoryList(
        categoryListRes.map((item: EventCategory) => ({
          label: item.categoryName,
          value: item.categoryName,
        })),
      );
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    initEventCategoryList();
  }, [initEventCategoryList]);

  //Function for tab 2
  const changeStatusButtonOnClick = async (
    applicationId: string,
    status: string,
  ) => {
    setClickedJobApplicationId(parseInt(applicationId));
    setClickedJobStatus(status);
    setChangeStatusVisible(true);
  };
  const changeStatusConfirm = async () => {
    setLoading(true);
    try {
      if (clickedJobApplicationId) {
        await updateOneJobApplication(clickedJobApplicationId, {
          status: clickedJobStatus,
        });
        toast.success('成功確更改申請記錄狀態');
        setLoading(false);
        setChangeStatusVisible(false);
        await new Promise(resolve => setTimeout(resolve, 1200));
        history.go(0);
      }
    } catch (err) {
      toast.error('更改申請記錄狀態失敗，請重試。');
      setLoading(false);
      setChangeStatusVisible(false);
    }
  };

  const TITLE = `檢視公司`;
  const BreadcrumbsOptions = [
    { title: '公司', path: '/companies' },
    { title: '公司列表', path: '/companies' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="檢視公司" breadcrumbItems={BreadcrumbsOptions} />
          {!isPreloader ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row className="mb-5 mt-4">
                      <Col sm={9}>
                        <Row>
                          <Col
                            sm={3}
                            className="d-sm-block d-flex flex-xs-row  justify-content-center"
                          >
                            <span className="font-size-24 text-secondary">
                              {!company?.logo ? (
                                company?.name ? (
                                  company?.name.charAt(0)
                                ) : (
                                  <i className="bx bx-event" />
                                )
                              ) : (
                                <img
                                  src={getImageURL(company?.logo)}
                                  style={{
                                    objectFit: 'contain',
                                    width: 120,
                                    height: 100,
                                  }}
                                  alt={
                                    company?.name
                                      ? company?.name
                                      : 'comapny profile'
                                  }
                                />
                              )}
                            </span>
                          </Col>
                          <Col sm={9}>
                            <ReactstrapTable borderless>
                              <tbody>
                                <tr>
                                  <td>
                                    <h6 className="mb-1 font-size-14">
                                      公司編號 : #
                                      {company?.companyId
                                        ? company?.companyId
                                        : '-'}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-1 font-size-14">
                                      公司名稱 :{' '}
                                      {company?.name ? company?.name : '-'}
                                    </h6>
                                  </td>
                                </tr>
                              </tbody>
                            </ReactstrapTable>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        sm={3}
                        className="d-flex flex-row-reverse justify-content-center justify-content-lg-start align-items-center"
                      >
                        <Link
                          to={`/companies/edit/${companyId}`}
                          className="btn btn-warning bg-warning  "
                        >
                          編輯
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={`font-size-14 ${
                                activeTabNum === '1' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setActiveTabNum('1');
                              }}
                            >
                              職位記錄
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`font-size-14 ${
                                activeTabNum === '2' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setActiveTabNum('2');
                              }}
                            >
                              工作申請記錄
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`font-size-14 ${
                                activeTabNum === '3' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setActiveTabNum('3');
                              }}
                            >
                              活動記錄
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent className="mt-2" activeTab={activeTabNum}>
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12">
                                <CardHeader className="bg-white text-dark d-flex justify-content-between pt-3">
                                  <CardTitle className="font-size-20">
                                    職位記錄
                                  </CardTitle>
                                  <CardTitle className="font-size-20">
                                    總數: {companyJobListCount}
                                  </CardTitle>
                                </CardHeader>

                                <CardBody>
                                  <Row>
                                    <Col sm={12}>
                                      <Row>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="region"
                                            label="分區"
                                            placeholder="分區"
                                            options={regionList}
                                            onChange={(e: any) => {
                                              const params = new URLSearchParams(
                                                location.search,
                                              );
                                              if (e) {
                                                params.set('region', e.value);
                                                setDistrictList(
                                                  regionDistrict[e.value]
                                                    .options,
                                                );
                                              } else {
                                                params.delete('region');
                                                setDistrictList([]);
                                              }
                                              params.delete('page');
                                              params.delete('district');
                                              history.push({
                                                search: params.toString(),
                                              });
                                            }}
                                            removeOnChangeDefaultBehavior
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="district"
                                            label="地域"
                                            placeholder="地域"
                                            options={districtList}
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="employmentType"
                                            label="僱用類型"
                                            placeholder="僱用類型"
                                            options={Object.keys(
                                              EmploymentType,
                                            ).map(key => ({
                                              label: EmploymentType[key],
                                              value: key,
                                            }))}
                                            isClearable
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <Table
                                          columns={JobListColumns()}
                                          isAllowSorting={false}
                                          keyField="jobApplicationId"
                                          data={companyJobList || []}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-3">
                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                      <Pagination
                                        current={companyJobListPage}
                                        count={companyJobListCount}
                                        pageSize={SUBPAGE_PAGE_SIZE}
                                        disableAutoScrollToTop={true}
                                        onChange={(page: number) => {
                                          setCompanyJobListPage(page);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12">
                                <CardHeader className="bg-white text-dark d-flex justify-content-between pt-3">
                                  <CardTitle className="font-size-20">
                                    工作申請記錄
                                  </CardTitle>
                                  <CardTitle className="font-size-20">
                                    總數: {companyJobApplicationListCount}
                                  </CardTitle>
                                </CardHeader>

                                <CardBody>
                                  <Row>
                                    <Col sm={12}>
                                      <Row>
                                        {currentRole ===
                                          AdminRoleEnum.超級管理員 ||
                                        currentRole === AdminRoleEnum.職員 ? (
                                          <Col lg={4} sm={12}>
                                            <AutoCompleteSelect
                                              name="userId"
                                              label="會員"
                                              placeholder="會員 ( 會員名稱 / 電話 )"
                                              options={userList}
                                              onMenuScrollToBottom={
                                                userScrollToBottom
                                              }
                                              inputValue={userText}
                                              onInputChange={text =>
                                                setUserText(text)
                                              }
                                              getOneData={
                                                updateUserListWithItemCall
                                              }
                                            />
                                          </Col>
                                        ) : null}
                                        <Col lg={4} sm={12}>
                                          <AutoCompleteSelect
                                            name="jobId"
                                            label="工作機會"
                                            placeholder="工作機會"
                                            inputValue={jobText}
                                            onInputChange={setJobText}
                                            options={jobList}
                                            onMenuScrollToBottom={
                                              onJobMenuScrollToBottom
                                            }
                                            getOneData={
                                              updateJobListWithItemCall
                                            }
                                          />
                                        </Col>
                                        <Col lg={4} sm={12}>
                                          <SelectFilter
                                            label="狀態"
                                            placeholder="狀態"
                                            name="status"
                                            options={
                                              JOB_APPLICATION_STATUS_OPTIONS
                                            }
                                            isClearable
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <Table
                                          columns={JobApplicationListColumns(
                                            changeStatusButtonOnClick,
                                            currentRole,
                                            loading,
                                          )}
                                          isAllowSorting={false}
                                          keyField="jobApplicationId"
                                          data={companyJobApplicationList || []}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-3">
                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                      <Pagination
                                        current={companyJobApplicationListPage}
                                        count={companyJobApplicationListCount}
                                        pageSize={SUBPAGE_PAGE_SIZE}
                                        disableAutoScrollToTop={true}
                                        onChange={(page: number) => {
                                          setCompanyJobApplicationListPage(
                                            page,
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="3">
                            <Row>
                              <Col sm="12">
                                <CardHeader className="bg-white text-dark d-flex justify-content-between pt-3">
                                  <CardTitle className="font-size-20">
                                    活動記錄
                                  </CardTitle>
                                  <CardTitle className="font-size-20">
                                    總數: {companyEventListCount}
                                  </CardTitle>
                                </CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col sm={12}>
                                      <Row>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="category"
                                            label="服務分類"
                                            placeholder="服務分類"
                                            options={categoryList}
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="region"
                                            label="分區"
                                            placeholder="分區"
                                            options={regionList}
                                            onChange={(e: any) => {
                                              const params = new URLSearchParams(
                                                location.search,
                                              );
                                              if (e) {
                                                params.set('region', e.value);
                                                setDistrictList(
                                                  regionDistrict[e.value]
                                                    .options,
                                                );
                                              } else {
                                                params.delete('region');
                                                setDistrictList([]);
                                              }
                                              params.set('page', '1');
                                              params.delete('district');
                                              history.push({
                                                search: params.toString(),
                                              });
                                            }}
                                            removeOnChangeDefaultBehavior
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="district"
                                            label="地域"
                                            placeholder="地域"
                                            options={districtList}
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="isRecommended"
                                            label="推薦活動"
                                            placeholder="推薦活動"
                                            options={YES_NO_OPTIONS}
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <SelectFilter
                                            name="status"
                                            label="狀態"
                                            placeholder="狀態"
                                            options={EVENT_STATUS_OPTIONS}
                                            isClearable
                                          />
                                        </Col>
                                        <Col lg={3}>
                                          <DatePickerFilter
                                            name="startDate"
                                            name2="endDate"
                                            label="創建日期範圍"
                                            placeholder="創建日期範圍"
                                            mode="range"
                                            isClearable
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <Table
                                          columns={EventListColumns(
                                            regionDistrict,
                                          )}
                                          isAllowSorting={false}
                                          keyField="eventId"
                                          data={companyEventList || []}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-3">
                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                      <Pagination
                                        current={companyEventListPage}
                                        count={companyEventListCount}
                                        pageSize={SUBPAGE_PAGE_SIZE}
                                        disableAutoScrollToTop={true}
                                        onChange={(page: number) => {
                                          setCompanyEventListPage(page);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>

        <Dialog
          visible={changeStatusVisible}
          title="更改狀態"
          onClose={() => setChangeStatusVisible(false)}
          loading={loading}
          onConfirm={changeStatusConfirm}
        >
          <p>{`確定要更改申請記錄狀態（編號: ${clickedJobApplicationId}）?`}</p>
        </Dialog>
      </div>
    </>
  );
};
