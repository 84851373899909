//i18n
// import { useTranslation } from 'react-i18next';
// users
// import user1 from 'app/assets/images/users/avatar.png';
import { ADMIN_ROLES } from 'app/constants';
import { getImageURL } from 'app/helpers/CommonHelper';
import { AdminRoleEnum } from 'app/models';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store';
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { RootState } from 'store';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const { Auth } = useSelector((rootState: RootState) => rootState.auth);
  const currentRole = Auth?.role;
  const avatar = Auth?.avatar;
  const adminCompany = Auth?.Company;
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState('Admin');
  // const { t } = useTranslation();

  useEffect(() => {
    if (Auth) {
      setusername(Auth.displayName);
    }
  }, [Auth]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect d-flex justify-content-between align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {avatar ? (
            <img
              className="rounded-circle avatar-xs"
              src={getImageURL(avatar)}
              alt=""
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <span className="avatar-xs ">
              <span className="avatar-title rounded-circle  bg-light text-secondary">
                {username ? username.charAt(0) : <i className="bx bx-user" />}{' '}
              </span>
            </span>
          )}
          <span className=" d-xs-inline-block ms-2 me-1">{username}</span>
          <Badge
            pill
            className={`badge-soft-${
              ADMIN_ROLES.get(
                currentRole ? currentRole : AdminRoleEnum.超級管理員,
              )?.color
            } ms-1 p-2 font-size-12 rounded badge-pill`}
          >
            {
              ADMIN_ROLES.get(
                currentRole ? currentRole : AdminRoleEnum.超級管理員,
              )?.label
            }{' '}
            {adminCompany ? (
              <>
                {' '}
                |{' '}
                <Link to={`/companies/detail/${adminCompany.companyId}`}>
                  {adminCompany.name}
                </Link>
              </>
            ) : (
              ''
            )}
          </Badge>
          <span className="d-none d-lg-inline-block ms-2 me-1">
            <i className="mdi mdi-chevron-down d-none d-md-inline-block" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
