import { CompanyResonse, User } from './';
import { TimeStamp } from './Common';

export interface EventDate {
  date: string;
}
export enum EventStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface Event extends TimeStamp {
  eventId: number;
  rootCategory: string;
  categoryName: string;
  types: string[];
  title: string;
  endedAt: string;
  description: string;
  lat: number;
  lng: number;
  EventDates: EventDate[];
  startTime: string;
  endTime: string;
  region: string;
  district: string;
  address: string;
  target: string;
  quota: number;
  price: number;
  url: string;
  images: string[];
  isRecommended: boolean;
  contactPerson: string;
  contactPhone: string;
  status: EventStatus;
  companyId: number;
  Company: CompanyResonse;
}

export interface EventCategory {
  categoryName: string;
  count: number;
  ordering: number;
}

export enum EventApplicationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface EventApplication extends TimeStamp {
  eventApplicationId: number;
  userId: number;
  eventId: number;
  name: string | null;
  phone: string | null;
  status: string;
  User: User;
  Event: Event;
}
