import React from 'react';

const Breadcrumb = ({ count, title }: { count: number; title: string }) => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      <div className="text-dark font-weight-bold" style={{ fontWeight: 700 }}>
        {title}
      </div>
      <div className="font-size-24 text-muted ms-2">{count}</div>
    </div>
  );
};

export default Breadcrumb;
