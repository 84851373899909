import clsx from 'clsx';
import 'flatpickr/dist/themes/material_blue.css';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import DatePicker from 'react-multi-date-picker';
import { Input, InputGroup, Label } from 'reactstrap';

interface OtherProps {
  label: string;
  placeholder?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange?: (value) => void;
}

const MultiDateField = (
  props: OtherProps & FieldHookConfig<string[] | null>,
) => {
  const [field, meta, helpers] = useField(props);
  const { label, placeholder, minDate, maxDate, onChange } = props;
  const CustomInput = ({ openCalendar, value }) => {
    return (
      <div className="mb-2 form-group">
        <Input
          className="form-control"
          onClick={openCalendar}
          value={value ? value : ''}
          type={'textarea'}
          autoComplete="new-password"
          readOnly
          rows={5}
        />
      </div>
    );
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 'unset' }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <InputGroup>
        <DatePicker
          containerStyle={{
            width: '100%',
          }}
          sort
          format="YYYY-MM-DD"
          inputClass="form-control d-block bg-white"
          placeholder={placeholder || '日期'}
          onChange={value => {
            if (onChange) {
              onChange(value);
            } else {
              if (value) {
                let returnDate: string[] = [];
                if (Array.isArray(value)) {
                  const dates = value.map(date => date.format());
                  returnDate = dates;
                } else {
                  returnDate = [value.format()];
                }
                helpers.setValue(returnDate);
              } else {
                helpers.setValue([]);
              }
            }
          }}
          value={field.value ? field.value : []}
          minDate={minDate}
          maxDate={maxDate}
          render={<CustomInput openCalendar={undefined} value={undefined} />}
        />
      </InputGroup>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default MultiDateField;
