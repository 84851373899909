import { OptionItems } from 'app/models';

export const USER_SEX_OPTIONS: OptionItems[] = [
  {
    label: '男',
    value: 'male',
  },
  {
    label: '女',
    value: 'female',
  },
];

export const USER_STATUS_OPTION: OptionItems[] = [
  { value: '已啟用', label: '已啟用' },
  { value: '已停用', label: '已停用' },
];
export const CARER_ROLE_OPTIONS: OptionItems[] = [
  { value: '長者', label: '長者' },
  { value: '殘疾人士', label: '殘疾人士' },
  { value: '長期病患人士', label: '長期病患人士' },
  { value: '受情緒困擾人士', label: '受情緒困擾人士' },
  { value: '一般兒童', label: '一般兒童' },
  { value: '特殊學習需要人士', label: '特殊學習需要人士' },
  { value: '其他', label: '其他' },
];
