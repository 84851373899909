import { TableCount } from 'app/components/Common';
import Breadcrumbs from 'app/components/Common/Breadcrumb';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import Dialog from 'app/components/Modal/Modal';
import { Pagination, Table } from 'app/components/Table';
import { API_URL, APP_NAME, PAGE_SIZE } from 'app/config';
import { EVENT_APPLICATION_STATUS_OPTIONS } from 'app/constants';
import { useAutoComplete, useTableComponentForListPage } from 'app/hooks';
import { Event, EventApplication, User } from 'app/models';
import {
  batchUpdateEventApplication,
  getEventApplicationsList,
  GetEventApplicationsListParams,
  getEventList,
  GetEventListParams,
  getOneEvent,
} from 'app/services/EventService';
import {
  getOneUser,
  getUserList,
  GetUserListParams,
} from 'app/services/UserService';
import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import EventApplicationListColumns from './EventApplicationListColumns';

export const EventApplicationListPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [clickedEventApplicationId, setClickedEventApplicationId] = useState<
    number
  >(0);
  const [approveVisible, setApproveVisible] = useState<boolean>(false);
  const [cancelVisible, setCancelVisible] = useState<boolean>(false);
  const [eventApplicationMap, setEventApplicationMap] = useState<
    Record<number, EventApplication>
  >({});
  const [batchApproveVisible, setBatchApproveVisible] = useState<boolean>(
    false,
  );
  const [batchRejectVisible, setBatchRejecVisible] = useState<boolean>(false);
  const [, setURL] = useState<string>(
    `${API_URL}/v1/events/applications/report?`,
  );
  const {
    dataList: eventApplicationList,
    count,
    page,
    setFilter,
    filter,
  } = useTableComponentForListPage<
    GetEventApplicationsListParams,
    EventApplication
  >({
    call: getEventApplicationsList,
    filterVariableArray: [
      'eventId',
      'userId',
      'createdAtFrom',
      'createdAtTo',
      'status',
      'desc',
      'asc',
    ],
  });
  const {
    searchText: userText,
    setSearchText: setUserText,
    dataList: userList,
    onMenuScrollToBottom: userScrollToBottom,
    updateDataListWithItemCall: updateUserListWithItemCall,
  } = useAutoComplete<GetUserListParams, User>({
    getItemCall: getOneUser,
    getListCall: getUserList,
    labelRender: (item: User) => ({
      label: `#${item.userId} | ${item.displayName} | ${item.phoneNumber}`,
      value: item.userId.toString(),
    }),
    keyExtracter: (item: User) => item.userId,
    searchTextFieldName: 'q',
  });

  const {
    searchText: eventsText,
    setSearchText: setEventText,
    dataList: eventList,
    onMenuScrollToBottom: eventScrollToBottom,
    updateDataListWithItemCall: updateEventListWithItemCall,
  } = useAutoComplete<GetEventListParams, Event>({
    getItemCall: getOneEvent,
    getListCall: getEventList,
    labelRender: (item: Event) => ({
      label: `#${item.eventId} | ${item.title}`,
      value: item.eventId.toString(),
    }),
    keyExtracter: (item: Event) => item.eventId,
    searchTextFieldName: 'title',
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');
    const eventId = params.get('eventId');
    const status = params.get('status');
    const createdAtFrom = params.get('createdAtFrom');
    const createdAtTo = params.get('createdAtTo');
    setURL(
      `${API_URL}/v1/events/applications/report?${
        userId ? `userId=${userId}&` : ''
      }${eventId ? `eventId=${eventId}&` : ''}${
        status ? `status=${status}&` : ''
      }${createdAtFrom ? `createdAtFrom=${createdAtFrom}&` : ''}${
        createdAtTo ? `createdAtTo=${createdAtTo}&` : ''
      }`,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const itemOnCheck = (item: EventApplication) => {
    const tempMap = { ...eventApplicationMap };
    if (tempMap[item.eventApplicationId]) {
      delete tempMap[item.eventApplicationId];
    } else {
      tempMap[item.eventApplicationId] = item;
    }
    setEventApplicationMap(tempMap);
  };

  const checkAllOnPress = () => {
    const tempMap = { ...eventApplicationMap };
    const length = Object.keys(tempMap).length;
    if (
      length === PAGE_SIZE ||
      length === eventApplicationList.length ||
      length ===
        eventApplicationList.filter(
          (item: EventApplication) => item.status === 'Pending',
        ).length
    ) {
      setEventApplicationMap({});
    } else if (length < PAGE_SIZE) {
      const selectedMap: Record<
        number,
        EventApplication
      > = eventApplicationList.reduce((map, item) => {
        if (item.status === 'Pending') {
          map[item.eventApplicationId] = item;
        }
        return map;
      }, {});
      setEventApplicationMap(selectedMap);
    }
  };

  const approveButtonOnClick = async (eventId: string) => {
    setClickedEventApplicationId(parseInt(eventId));

    setApproveVisible(true);
  };

  const approveConfirm = async () => {
    setLoading(true);
    try {
      await batchUpdateEventApplication([
        {
          eventApplicationId: clickedEventApplicationId,
          status: 'Approved',
        },
      ]);
      toast.success('成功批准待確認名額');
      setLoading(false);
      setApproveVisible(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.error('批准待確認名額失敗，請重試。');
      setLoading(false);
      setApproveVisible(false);
    }
  };
  const cancelButtonOnClick = async (eventId: string) => {
    setClickedEventApplicationId(parseInt(eventId));
    setCancelVisible(true);
  };

  const cancelConfirm = async () => {
    setLoading(true);
    try {
      await batchUpdateEventApplication([
        {
          eventApplicationId: clickedEventApplicationId,
          status: 'Rejected',
        },
      ]);
      toast.success('成功拒絕待確認名額');
      setLoading(false);
      setCancelVisible(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.error('拒絕失敗待確認名額，請重試。');
      setLoading(false);
      setCancelVisible(false);
    }
  };

  const batchApproveConfirm = async () => {
    setLoading(true);
    try {
      const eventApplicationsArray = Object.keys(eventApplicationMap).map(
        (key: string) => ({
          eventApplicationId: parseInt(key),
          status: 'Approved',
        }),
      );
      await batchUpdateEventApplication(eventApplicationsArray);
      setFilter({ ...filter });
      toast.success('成功批准所有已選待確認名額。');
      setEventApplicationMap({});
      setBatchApproveVisible(false);
      setLoading(false);
    } catch (err) {
      toast.warning('批准所有已選待確認名額，請重試。');
      setLoading(false);
      setBatchApproveVisible(false);
    }
  };

  const batchRejectConfirm = async () => {
    setLoading(true);
    try {
      const eventApplicationsArray = Object.keys(eventApplicationMap).map(
        (key: string) => ({
          eventApplicationId: parseInt(key),
          status: 'Rejected',
        }),
      );
      await batchUpdateEventApplication(eventApplicationsArray);
      setFilter({ ...filter });
      setEventApplicationMap({});
      toast.success('成功拒絕所有已選待確認名額。');
      setBatchRejecVisible(false);
      setLoading(false);
    } catch (err) {
      toast.warning('拒絕所有已選待確認名額失敗，請重試。');
      setLoading(false);
      setBatchRejecVisible(false);
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>報名記錄列表 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="報名記錄" breadcrumbItem="報名記錄列表" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col lg={9}>
                      <Row>
                        <Col lg={4} sm={12}>
                          <AutoCompleteSelect
                            name="userId"
                            label="會員"
                            placeholder="會員 ( 會員名稱 / 電話 )"
                            options={userList}
                            onMenuScrollToBottom={userScrollToBottom}
                            inputValue={userText}
                            onInputChange={text => setUserText(text)}
                            getOneData={updateUserListWithItemCall}
                          />
                        </Col>
                        <Col lg={4} sm={12}>
                          <AutoCompleteSelect
                            name="eventId"
                            label="中心活動"
                            placeholder="中心活動"
                            options={eventList}
                            onMenuScrollToBottom={eventScrollToBottom}
                            inputValue={eventsText}
                            onInputChange={text => setEventText(text)}
                            getOneData={updateEventListWithItemCall}
                          />
                        </Col>
                        <Col lg={4} sm={12}>
                          <SelectFilter
                            label="狀態"
                            placeholder="狀態"
                            name="status"
                            options={EVENT_APPLICATION_STATUS_OPTIONS}
                            onChange={e => setEventApplicationMap({})}
                            isClearable
                          />
                        </Col>
                        <Col lg={4} sm={12}>
                          <DatePickerFilter
                            label="創建日期範圍"
                            name="createdAtFrom"
                            name2="createdAtTo"
                            placeholder="創建日期範圍"
                            mode="range"
                            isClearable
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg={3}
                      sm={12}
                      className="d-flex flex-row-reverse align-items-center"
                    >
                      <Button
                        className="ms-1 mb-2"
                        color="success"
                        onClick={() => setBatchApproveVisible(true)}
                        disabled={
                          Object.keys(eventApplicationMap).length === 0 ||
                          loading
                        }
                      >
                        批准
                      </Button>
                      <Button
                        className="ms-1 me-1 mb-2"
                        color="danger"
                        onClick={() => setBatchRejecVisible(true)}
                        disabled={
                          Object.keys(eventApplicationMap).length === 0 ||
                          loading
                        }
                      >
                        拒絕
                      </Button>
                      {/* <Link className=" btn btn-info mb-2 " to={url}>
                        匯出以下列表
                      </Link> */}

                      {/* <Link
                        to={`/events/applications/new`}
                        className="ms-1 btn btn-primary mb-2 "
                        role="button"
             
                      >
                        {` ${'新增報名記錄'}`}
                      </Link> */}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col
                      sm={12}
                      className="d-flex align-self-end justify-content-end"
                    >
                      <TableCount count={count} title="報名記錄總數" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <Table
                          columns={EventApplicationListColumns(
                            approveButtonOnClick,
                            cancelButtonOnClick,
                            loading,
                          )}
                          keyField="eventApplicationId"
                          data={eventApplicationList || []}
                          checkableConfig={{
                            isCheckBoxShow: (item: EventApplication) =>
                              item.status === 'Pending',
                            onCheck: itemOnCheck,
                            selected: eventApplicationMap,
                            checkAll: checkAllOnPress,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                      <Pagination
                        count={count}
                        current={page}
                        onChange={page => {
                          setEventApplicationMap({});
                          const params = new URLSearchParams(location.search);
                          params.set('page', page.toString());
                          history.push({ search: params.toString() });
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Dialog
          visible={cancelVisible}
          title="拒絕待確認名額"
          onClose={() => setCancelVisible(false)}
          loading={loading}
          onConfirm={cancelConfirm}
        >
          <p>{`確定要拒絕待確認名額（編號: ${clickedEventApplicationId}）?`}</p>
        </Dialog>
        <Dialog
          visible={approveVisible}
          title={`批准待確認名額`}
          onClose={() => setApproveVisible(false)}
          loading={loading}
          onConfirm={approveConfirm}
        >
          <p>{`確定要批准待確認名額 (編號: ${clickedEventApplicationId})`}</p>
        </Dialog>
        <Dialog
          visible={batchApproveVisible}
          title={`批准已選待確認名額數量: ${
            Object.keys(eventApplicationMap).length
          }`}
          onClose={() => setBatchApproveVisible(false)}
          loading={loading}
          onConfirm={batchApproveConfirm}
        >
          <p>{`確定要批准待所有已選確認名額?`}</p>
        </Dialog>
        <Dialog
          visible={batchRejectVisible}
          title={`拒絕已選待確認名額數量: ${
            Object.keys(eventApplicationMap).length
          }`}
          onClose={() => setBatchRejecVisible(false)}
          loading={loading}
          onConfirm={batchRejectConfirm}
        >
          <p>{`確定要拒絕所有已選待確認名額?`}</p>
        </Dialog>
      </div>
    </>
  );
};
