import {
  Admin,
  AdminLoginPayload,
  AdminRoleEnum,
  CompanyResonse,
  Event,
  Filter,
  Job,
  JobApplication,
  ListResult,
} from '../models';
import Axios from './';
import { GetEventListParams } from './EventService';
import { GetJobApplicationsListParams } from './JobService';

export const adminLogin = async (param: AdminLoginPayload) => {
  const { data } = await Axios.post<string>(
    'admins/passwordAuth/SignIn',
    param,
  );
  return data;
};

export interface GetAdminListParams extends Filter {
  role?: AdminRoleEnum;
  companyId: number;
  ß;
}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>(
    '/admins/adminsWithAuth',
    {
      params,
    },
  );
  return data;
};

export interface CreateNewAdminParams {
  avatar: any;
  email?: string | null;
  loginName?: string;
  password?: string | null;
  displayName: string;
  role: AdminRoleEnum;
  companyId: number | null;
}
export const createNewAdmin = async (param: CreateNewAdminParams) => {
  const { data } = await Axios.post<Admin>(
    '/admins/passwordAuth/createUser',
    param,
  );
  return data;
};

export const getSingleAdmin = async (adminId: string) => {
  const { data } = await Axios.get<Admin>(`/admins/${adminId}`);
  return data;
};

export const getSingleAdminAuth = async (adminId: string) => {
  const { data } = await Axios.get<Admin[]>(`/admins/${adminId}/auths`);
  return data;
};

export const updateAdmin = async (
  adminId: number,
  param: Partial<CreateNewAdminParams>,
) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, param);
  return data;
};

export const deleteAdmin = async (adminId: number) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<Admin>('/admins/me/profile');
  return data;
};

export const resetAdminPassword = async (
  adminId: number,
  param: { newPassword?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admins/${adminId}/resetPassword`,
    param,
  );
  return data;
};

export const adminLogout = async () => {
  const { data } = await Axios.post('/admins/me/logout');
  return data;
};

export interface GetJobListParams extends Filter {
  title?: string;
  region?: string;
  district?: string;
  companyId?: string;
  industry?: string;
  payrollPeriod?: string;
  salaryFrom?: string;
  salaryTo?: string;
  employmentType?: string;
}

export const getJobForCompanyAdmin = async (params: GetJobListParams) => {
  const { data } = await Axios.get<ListResult<Job>>('admins/me/jobs', {
    params,
  });
  return data;
};

export const getJobApplicationsForCompanyAdmin = async (
  params: GetJobApplicationsListParams,
) => {
  const { data } = await Axios.get<ListResult<JobApplication>>(
    'admins/me/jobApplications',
    {
      params,
    },
  );
  return data;
};

export interface GetCompanyListParams extends Filter {
  name?: string;
}

export const getCompaniesForCompanyAdmin = async (
  params: GetCompanyListParams,
) => {
  const { data } = await Axios.get<ListResult<CompanyResonse>>(
    'admins/me/companies',
    {
      params,
    },
  );
  return data;
};

export const getEventForCompanyAdmin = async (params: GetEventListParams) => {
  const { data } = await Axios.get<ListResult<Event>>('admins/me/events', {
    params,
  });
  return data;
};
