/**
 *
 * Asynchronously loads the component for JobFormPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const JobFormPage = lazyLoad(
  () => import('./index'),
  module => module.JobFormPage,
);
