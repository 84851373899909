import {
  ColorOptionItems,
  JobApplicationStatus,
  JobStatus,
  OptionItems,
} from 'app/models';

export const JOB_STATUS_OPTIONS: OptionItems[] = [
  {
    label: '批准中',
    value: JobStatus.Pending,
  },
  {
    label: '已批准',
    value: JobStatus.Approved,
  },
  {
    label: '已取消',
    value: JobStatus.Rejected,
  },
];
export const JobStatusMap = new Map<string, ColorOptionItems>([
  [
    'Pending',
    {
      label: '批准中',
      value: JobStatus.Pending,
      colorType: 'secondary',
    },
  ],
  [
    'Approved',
    {
      label: '已批准',
      value: JobStatus.Approved,
      colorType: 'success',
    },
  ],
  [
    'Rejected',
    {
      label: '已取消',
      value: JobStatus.Rejected,
      colorType: 'danger',
    },
  ],
]);

export const JOB_APPLICATION_STATUS_OPTIONS: OptionItems[] = [
  {
    label: '未處理',
    value: JobApplicationStatus.Pending,
  },
  {
    label: '已處理',
    value: JobApplicationStatus.Approved,
  },
  {
    label: '已取消',
    value: JobApplicationStatus.Rejected,
  },
];
export const JobApplicationStatusMap = new Map<string, ColorOptionItems>([
  [
    'Pending',
    {
      label: '未處理',
      value: JobApplicationStatus.Pending,
      colorType: 'secondary',
    },
  ],
  [
    'Approved',
    {
      label: '已處理',
      value: JobApplicationStatus.Approved,
      colorType: 'success',
    },
  ],
  [
    'Rejected',
    {
      label: '已取消',
      value: JobApplicationStatus.Rejected,
      colorType: 'danger',
    },
  ],
]);
