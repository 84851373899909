import { Filter, ListResult, User, UserSex, UserStatus } from '../models';
import Axios from './';

/**
 *  Get User List (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_listAllUsers)
 */

export interface GetUserListParams extends Filter {
  q?: string;
  status?: UserStatus;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/users', {
    params,
  });
  return data;
};

/**
 *  Create new user (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_createNewUserByAdmin)
 */
export interface CreateNewUserParams {
  displayName: string;
  avatar: any;
  sex: UserSex | null;
  dateOfBirth: string;
  livingRegion: string;
  phoneNumber: string;
  whatsappNumber: string;
  carerRoles: string[];
  status: UserStatus;
  district: string;
}

export const createNewUser = async (params: CreateNewUserParams) => {
  const { data } = await Axios.post<User>('/users', params);
  return data;
};

/**
 *  Get One User (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_getOneUserByAdmin)
 */

export const getOneUser = async (userId: number) => {
  const { data } = await Axios.get<User>(`/users/${userId}`);
  return data;
};

/**
 *  Update One User (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_updateUserByAdmin)
 */

export const updateOneUser = async (
  userId: number,
  params: Partial<CreateNewUserParams>,
) => {
  const { data } = await Axios.patch<null>(`/users/${userId}`, params);
  return data;
};
/**
 *  Delete one user  (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_deleteUserByAdmin)
 */
export const deleteOneUser = async (userId: string) => {
  const { data } = await Axios.delete<string>(`/users/${userId}`);
  return data;
};

/**
 *  Activiate one user  (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_activateUserByAdmin)
 */

export const activateUserAccount = async (userId: number) => {
  const { data } = await Axios.post<any>(`/users/${userId}/activate`);
  return data;
};

/**
 *  Disactivate one user  (https://jcabc-app-dev.appicidea.com/doc/#/User/UsersController_deactivateUserByAdmin)
 */

export const disactivateUserAccount = async (userId: number) => {
  const { data } = await Axios.post<any>(`/users/${userId}/deactivate`);
  return data;
};
