/**
 *
 * Asynchronously loads the component for CompanyListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CompanyListPage = lazyLoad(
  () => import('./index'),
  module => module.CompanyListPage,
);
