/* eslint-disable jsx-a11y/role-supports-aria-props */
// MetisMenu
import { AdminRoleEnum } from 'app/models';
import MetisMenu from 'metismenujs';
import React, { useCallback, useEffect, useRef } from 'react';
//i18n
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';
import { useAdminPermission } from 'store/Permission';

const SidebarContent = props => {
  // const { t } = useTranslation();
  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          const pathStr = pathName.split('/');
          let containPath = false;
          pathStr.forEach(str => {
            if (str && `/${str}` === items[i].pathname) {
              containPath = true;
            }
          });
          if (containPath) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  const { hasPermission } = useAdminPermission();

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/events">
                <i className="bx bx-calendar-event"></i>
                <span>中心活動</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/events/items"
                    activeClassName="active mm-active"
                  >
                    活動列表
                  </NavLink>
                </li>
                {hasPermission([
                  AdminRoleEnum.超級管理員,
                  AdminRoleEnum.職員,
                ]) ? (
                  <li>
                    <NavLink
                      to="/events/applications"
                      activeClassName="active mm-active"
                    >
                      報名記錄
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </li>
            <li>
              <NavLink
                to="/companies"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-buildings"></i>
                <span>公司及機構</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/jobs">
                <i className="bx bx-briefcase-alt"></i>
                <span>工作機會</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/jobs/items" activeClassName="active mm-active">
                    工作機會
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/jobs/applications"
                    activeClassName="active mm-active"
                  >
                    申請記錄
                  </NavLink>
                </li>
              </ul>
            </li>
            {hasPermission([AdminRoleEnum.超級管理員, AdminRoleEnum.職員]) ? (
              <li>
                <NavLink
                  to="/banners"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-layout"></i>
                  <span>橫幅廣告</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員, AdminRoleEnum.職員]) ? (
              <li>
                <NavLink
                  to="/centres"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-building"></i>
                  <span>照顧者資源</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員, AdminRoleEnum.職員]) ? (
              <li>
                <NavLink
                  to="/news"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-news"></i>
                  <span>最新資訊</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員, AdminRoleEnum.職員]) ? (
              <li>
                <NavLink
                  to="/usefulInfo?rootCategory=生活秘笈"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-book"></i>
                  <span>照顧秘笈</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員, AdminRoleEnum.職員]) ? (
              <li>
                <NavLink
                  to="/careerPlannings?rootCategory=%E7%85%A7%E9%A1%A7%E8%80%85%E7%AC%AC%E4%BA%8C%E4%BA%BA%E7%94%9F%E6%95%85%E4%BA%8B"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-paper-plane"></i>
                  <span>照顧者第二人生故事</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission([AdminRoleEnum.超級管理員]) ? (
              <li>
                <NavLink
                  to="/contacts"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-phone"></i>
                  <span>聯絡資料</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員]) ? (
              <li>
                <NavLink
                  to="/notifications"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-mail-send"></i>
                  <span>推送訊息</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員]) ? (
              <li>
                <NavLink className="has-arrow waves-effect" to="/sorting">
                  <i className="bx bx-sort"></i>
                  <span>排序</span>
                </NavLink>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <NavLink
                      to="/sorting/banners"
                      activeClassName="active mm-active"
                    >
                      橫幅廣告
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sorting/eventCategories"
                      activeClassName="active mm-active"
                    >
                      中心活動類別
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sorting/centreCategories?rootCategory=社區人士"
                      activeClassName="active mm-active"
                    >
                      照顧者資源類別
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sorting/newsCategories"
                      activeClassName="active mm-active"
                    >
                      最新資訊類別
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/sorting/usefulInfoCategories"
                      activeClassName="active mm-active"
                    >
                      照顧秘笈類別
                    </NavLink>
                  </li>
                </ul>
              </li>
            ) : null}

            {hasPermission([AdminRoleEnum.超級管理員, AdminRoleEnum.職員]) ? (
              <li>
                <NavLink
                  to="/users"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-user"></i>
                  <span>會員</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員]) ? (
              <li>
                <NavLink
                  to="/take-down"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="mdi mdi-archive-arrow-down"></i>
                  <span>移除申請</span>
                </NavLink>
              </li>
            ) : null}
            {hasPermission([AdminRoleEnum.超級管理員]) ? (
              <li>
                <NavLink
                  to="/admins"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-user-circle"></i>
                  <span>管理員</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
