/**
 * index.tsx
 *
 */

// Import root app
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { App } from 'app';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// Use consistent styling
import 'sanitize.css/sanitize.css';
// Initialize languages
import './locales/i18n';
import { persistor, store } from './store';

Bugsnag.start({
  apiKey:
    process.env.REACT_APP_BUGSNAG_API_KEY || 'd29a9e3273cf5e078129486395d80841',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  appVersion: process.env.REACT_APP_VERSION,
  autoTrackSessions: false,
});

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
  MOUNT_NODE,
);
