import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import Dialog from 'app/components/Modal/Modal';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME, PAGE_SIZE, SUBPAGE_PAGE_SIZE } from 'app/config';
import {
  EventStatusMap,
  EVENT_APPLICATION_STATUS_OPTIONS,
} from 'app/constants';
import { getImageURL, getRegionDistrictObject } from 'app/helpers/CommonHelper';
import { useAutoComplete } from 'app/hooks';
import {
  AdminRoleEnum,
  Event,
  EventApplication,
  EventStatus,
  User,
} from 'app/models';
import EventApplicationListColumns from 'app/pages/EventApplicationPage/EventApplicationListPage/EventApplicationListColumns';
import {
  batchUpdateEventApplication,
  getEventApplicationsList,
  getOneEvent,
  updateOneEvent,
} from 'app/services/EventService';
import {
  getOneUser,
  getUserList,
  GetUserListParams,
} from 'app/services/UserService';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table as ReactstrapTable,
  TabPane,
} from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';

interface FormQuery {
  eventId: string;
}

export const EventDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const params = useParams<FormQuery>();
  const regionDistrict = getRegionDistrictObject();
  const { auth } = useSelector((rootState: RootState) => rootState);
  const currentRole = auth.Auth ? auth?.Auth?.role : '';
  const [event, setEvent] = useState<Event>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAllDate, setShowAllDate] = useState<boolean>(false);
  const [eventId, setEventId] = useState<number>();
  //Tab variables
  const [activeTabNum, setActiveTabNum] = useState<string>('1');
  const [eventApplicationMap, setEventApplicationMap] = useState<
    Record<number, EventApplication>
  >({});
  const [eventApplicationList, setEventApplicationList] = useState<
    EventApplication[]
  >([]);
  const [clickedEventApplicationId, setClickedEventApplicationId] = useState<
    number
  >(0);
  const [approveEventVisible, setApproveEventVisible] = useState<boolean>(
    false,
  );
  const [rejectEventVisible, setRejectEventVisible] = useState<boolean>(false);
  const [
    approveEventApplicationVisible,
    setApproveEventApplicationVisible,
  ] = useState<boolean>(false);
  const [
    cancelEventApplicationVisible,
    setCancelEventApplicationVisible,
  ] = useState<boolean>(false);
  const [
    batchApproveEventApplicationVisible,
    setBatchApproveEventApplicationVisible,
  ] = useState<boolean>(false);
  const [
    batchRejectEventApplicationVisible,
    setBatchRejectEventApplicationVisible,
  ] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const {
    searchText: userText,
    setSearchText: setUserText,
    dataList: userList,
    onMenuScrollToBottom: userScrollToBottom,
    updateDataListWithItemCall: updateUserListWithItemCall,
  } = useAutoComplete<GetUserListParams, User>({
    getItemCall: getOneUser,
    getListCall: getUserList,
    labelRender: (item: User) => ({
      label: `#${item.userId} | ${item.displayName} | ${item.phoneNumber}`,
      value: item.userId.toString(),
    }),
    keyExtracter: (item: User) => item.userId,
    searchTextFieldName: 'q',
  });

  const initPage = useCallback(async () => {
    dispatch(changePreloader(true));
    const { eventId: _eventId } = params;
    try {
      if (_eventId) {
        const eventRes = await getOneEvent(parseInt(_eventId));
        setEventId(parseInt(_eventId));
        setEvent(eventRes);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initPage();
  }, [initPage]);

  // Function in Tab

  const getApplicationRecords = useCallback(async () => {
    try {
      if (
        eventId &&
        (currentRole === AdminRoleEnum.超級管理員 ||
          currentRole === AdminRoleEnum.職員)
      ) {
        const params = new URLSearchParams(location.search);
        const userId = params.get('userId');
        const asc = params.get('asc');
        const desc = params.get('desc');
        const status = params.get('status');
        const createdAtFrom = params.get('createdAtFrom');
        const createdAtTo = params.get('createdAtTo');

        const applicationRes = await getEventApplicationsList({
          limit: SUBPAGE_PAGE_SIZE,
          offset: (page - 1) * SUBPAGE_PAGE_SIZE,
          eventId: eventId ? eventId.toString() : undefined,
          userId: userId ? userId : undefined,
          asc: asc ? asc : undefined,
          desc: desc ? desc : undefined,
          status: status ? status : undefined,
          createdAtFrom: createdAtFrom ? createdAtFrom : undefined,
          createdAtTo: createdAtTo ? createdAtTo : undefined,
        });
        setEventApplicationList(applicationRes.rows);
        setCount(applicationRes.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [eventId, currentRole, location.search, page]);
  useEffect(() => {
    getApplicationRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationRecords]);

  const itemOnCheck = (item: EventApplication) => {
    const tempMap = { ...eventApplicationMap };
    if (tempMap[item.eventApplicationId]) {
      delete tempMap[item.eventApplicationId];
    } else {
      tempMap[item.eventApplicationId] = item;
    }
    setEventApplicationMap(tempMap);
  };

  const checkAllOnPress = () => {
    const tempMap = { ...eventApplicationMap };
    const length = Object.keys(tempMap).length;
    if (
      length === PAGE_SIZE ||
      length === eventApplicationList.length ||
      length ===
        eventApplicationList.filter(
          (item: EventApplication) => item.status === 'Pending',
        ).length
    ) {
      setEventApplicationMap({});
    } else if (length < PAGE_SIZE) {
      const selectedMap: Record<
        number,
        EventApplication
      > = eventApplicationList.reduce((map, item) => {
        if (item.status === 'Pending') {
          map[item.eventApplicationId] = item;
        }
        return map;
      }, {});
      setEventApplicationMap(selectedMap);
    }
  };

  const approveButtonOnClick = async (eventId: string) => {
    setClickedEventApplicationId(parseInt(eventId));

    setApproveEventApplicationVisible(true);
  };

  const approveConfirm = async () => {
    setLoading(true);
    try {
      await batchUpdateEventApplication([
        {
          eventApplicationId: clickedEventApplicationId,
          status: 'Approved',
        },
      ]);
      toast.success('成功批准待確認名額');
      setLoading(false);
      setApproveEventApplicationVisible(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.error('批准待確認名額失敗，請重試。');
      setLoading(false);
      setApproveEventApplicationVisible(false);
    }
  };
  const cancelButtonOnClick = async (eventId: string) => {
    setClickedEventApplicationId(parseInt(eventId));
    setCancelEventApplicationVisible(true);
  };

  const cancelConfirm = async () => {
    setLoading(true);
    try {
      await batchUpdateEventApplication([
        {
          eventApplicationId: clickedEventApplicationId,
          status: 'Rejected',
        },
      ]);
      toast.success('成功拒絕待確認名額');
      setLoading(false);
      setCancelEventApplicationVisible(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.error('拒絕失敗待確認名額，請重試。');
      setLoading(false);
      setCancelEventApplicationVisible(false);
    }
  };

  const batchApproveConfirm = async () => {
    setLoading(true);
    try {
      const eventApplicationsArray = Object.keys(eventApplicationMap).map(
        (key: string) => ({
          eventApplicationId: parseInt(key),
          status: 'Approved',
        }),
      );
      await batchUpdateEventApplication(eventApplicationsArray);
      toast.success('成功批准所有已選待確認名額。');
      setEventApplicationMap({});
      setBatchApproveEventApplicationVisible(false);
      setLoading(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.warning('批准所有已選待確認名額，請重試。');
      setLoading(false);
      setBatchApproveEventApplicationVisible(false);
    }
  };

  const batchRejectConfirm = async () => {
    setLoading(true);
    try {
      const eventApplicationsArray = Object.keys(eventApplicationMap).map(
        (key: string) => ({
          eventApplicationId: parseInt(key),
          status: 'Rejected',
        }),
      );
      await batchUpdateEventApplication(eventApplicationsArray);
      setEventApplicationMap({});
      toast.success('成功拒絕所有已選待確認名額。');
      setBatchRejectEventApplicationVisible(false);
      setLoading(false);
      await new Promise(resolve => setTimeout(resolve, 1200));
      history.go(0);
    } catch (err) {
      toast.warning('拒絕所有已選待確認名額失敗，請重試。');
      setLoading(false);
      setBatchRejectEventApplicationVisible(false);
    }
  };
  const changeEventStatus = async (status: string) => {
    try {
      if (eventId) {
        setLoading(true);
        await updateOneEvent(eventId, { status: status });

        if (status === EventStatus.Approved) {
          setApproveEventVisible(false);
          toast.success('成功批准中心活動。');
        } else {
          setRejectEventVisible(false);
          toast.success('成功拒絕中心活動。');
        }
        setLoading(false);
        await new Promise(resolve => setTimeout(resolve, 800));
        history.go(0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const TITLE = `檢視中心活動`;
  const BreadcrumbsOptions = [
    { title: '中心活動', path: '/events/items' },
    { title: '中心活動列表', path: '/events/items' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="中心活動" breadcrumbItems={BreadcrumbsOptions} />
          {!isPreloader ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        sm={12}
                        className="d-flex flex-row-reverse justify-content-center justify-content-lg-start align-items-center"
                      >
                        <Link
                          to={`/events/items/edit/${event?.eventId}`}
                          className="btn btn-warning bg-warning"
                        >
                          編輯
                        </Link>
                        <Link
                          to={`/events/items/new?copyEventId=${event?.eventId}`}
                          className="btn btn-warning bg-secondary me-1"
                        >
                          複製活動
                        </Link>
                        {(currentRole === AdminRoleEnum.職員 ||
                          currentRole === AdminRoleEnum.超級管理員) &&
                        event?.status === EventStatus.Pending ? (
                          <>
                            <Button
                              className="me-1"
                              type="button"
                              color="success"
                              onClick={() => setApproveEventVisible(true)}
                            >
                              批准活動
                            </Button>
                            <Button
                              className="me-1"
                              type="button"
                              color="danger"
                              onClick={() => setRejectEventVisible(true)}
                            >
                              拒絕活動
                            </Button>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-5 mt-4">
                      <Col sm={12}>
                        <Row>
                          <Col
                            sm={3}
                            md={2}
                            className="d-sm-block d-flex flex-xs-row  justify-content-center"
                          >
                            <div className="avatar-xl">
                              <span className="avatar-title rounded-circle font-size-24 bg-light text-secondary">
                                {!event?.images ? (
                                  event?.title ? (
                                    event?.title.charAt(0)
                                  ) : (
                                    <i className="bx bx-event" />
                                  )
                                ) : (
                                  <img
                                    className="rounded-circle avatar-xl"
                                    src={getImageURL(event.images[0])}
                                    style={{ objectFit: 'contain' }}
                                    alt={
                                      event?.title
                                        ? event?.title
                                        : 'event profile'
                                    }
                                  />
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col sm={9} md={10}>
                            <ReactstrapTable borderless>
                              <tbody>
                                <tr>
                                  <td className="w-50">
                                    <h6 className="mb-1 font-size-14">
                                      活動編號 : #
                                      {event?.eventId ? event?.eventId : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      活動名稱 :{' '}
                                      {event?.title ? event?.title : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      活動類型 :{' '}
                                      {event?.rootCategory ? (
                                        <Badge
                                          pill
                                          className={`badge-soft-${
                                            event.rootCategory === '本中心活動'
                                              ? 'success'
                                              : 'dark'
                                          } ms-1 p-1 font-size-12`}
                                        >
                                          {event.rootCategory}
                                        </Badge>
                                      ) : (
                                        '-'
                                      )}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      所屬機構：{' '}
                                      {event?.Company ? (
                                        <Link
                                          to={`/companies/detail/${event.companyId}`}
                                        >
                                          {event.Company.name}
                                        </Link>
                                      ) : (
                                        '-'
                                      )}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      服務分類 :{' '}
                                      {event?.categoryName ? (
                                        <Badge
                                          pill
                                          className={`badge-soft-primary ms-1 p-1 font-size-12`}
                                        >
                                          {event.categoryName}
                                        </Badge>
                                      ) : (
                                        '-'
                                      )}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      照顧類別 :{' '}
                                      {event?.types ? (
                                        <>
                                          {event.types.map(type => (
                                            <Badge
                                              pill
                                              className={`badge-soft-warning ms-1 mb-1 p-1 font-size-12`}
                                            >
                                              {type}
                                            </Badge>
                                          ))}
                                        </>
                                      ) : (
                                        '-'
                                      )}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      開始時間 :{' '}
                                      {event?.startTime
                                        ? event?.startTime
                                        : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      結束時間 :{' '}
                                      {event?.endTime ? event?.endTime : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14 d-flex flex-row">
                                      <div style={{ minWidth: '70px' }}>
                                        活動日期 :{'  '}
                                      </div>
                                      <div>
                                        {event?.EventDates ? (
                                          <>
                                            {event?.EventDates.map(
                                              (date, index) => (
                                                <Badge
                                                  key={date.date}
                                                  pill
                                                  className={`badge-soft-info me-1 mb-1 p-1 font-size-12 d-${
                                                    index > 2 && !showAllDate
                                                      ? 'none'
                                                      : 'inline-block'
                                                  }`}
                                                >
                                                  {date.date}
                                                </Badge>
                                              ),
                                            )}
                                            {!showAllDate &&
                                            event?.EventDates.length > 3 ? (
                                              <Badge
                                                onClick={() =>
                                                  setShowAllDate(true)
                                                }
                                                pill
                                                className={`badge-soft-info me-1 mb-1 p-1 font-size-12`}
                                                role="button"
                                              >
                                                ...
                                              </Badge>
                                            ) : null}
                                          </>
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-1 font-size-14">
                                      結束報名日期 :{' '}
                                      {event?.endedAt
                                        ? moment(event?.endedAt).format(
                                            'DD-MM-YYYY',
                                          )
                                        : '-'}
                                    </h6>

                                    <h6 className="mb-1 font-size-14">
                                      推薦活動 :{' '}
                                      {event
                                        ? event.isRecommended
                                          ? '是'
                                          : '否'
                                        : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      對象 :{' '}
                                      {event?.target ? event?.target : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      人數上限 :{' '}
                                      {event?.quota ? event?.quota : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      收費 : {event?.price ? event?.price : '-'}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      區域 :
                                      {event?.region
                                        ? regionDistrict[event.region]
                                          ? regionDistrict[event.region].label
                                          : '-'
                                        : ''}{' '}
                                      {event?.district
                                        ? regionDistrict[event.region].map[
                                            event.district
                                          ]
                                        : ''}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      緯度及經度 :{' '}
                                      {event?.lat ? event?.lat : ''},
                                      {event?.lng ? event?.lng : ''}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      聯絡人名稱及電話 :{' '}
                                      {event?.contactPerson
                                        ? event?.contactPerson
                                        : '-'}{' '}
                                      {event?.contactPhone
                                        ? ` (${event?.contactPhone})`
                                        : ''}
                                    </h6>
                                    <h6 className="mb-1 font-size-14">
                                      狀態 :{' '}
                                      {event?.status ? (
                                        <Badge
                                          pill
                                          className={`badge-soft-${
                                            EventStatusMap.get(event.status)
                                              ?.colorType
                                          } ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
                                        >
                                          {
                                            EventStatusMap.get(event.status)
                                              ?.label
                                          }
                                        </Badge>
                                      ) : (
                                        '-'
                                      )}
                                    </h6>
                                  </td>
                                </tr>
                              </tbody>
                            </ReactstrapTable>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {currentRole === AdminRoleEnum.超級管理員 ||
                    currentRole === AdminRoleEnum.職員 ? (
                      <Row>
                        <Col sm={12}>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`font-size-14 ${
                                  activeTabNum === '1' ? 'active' : ''
                                }`}
                                onClick={() => {
                                  setActiveTabNum('1');
                                }}
                              >
                                報名記錄
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent className="mt-2" activeTab={activeTabNum}>
                            <TabPane tabId="1">
                              <Row>
                                <Col sm="12">
                                  <CardHeader className="bg-white text-dark d-flex justify-content-between pt-3">
                                    <CardTitle className="font-size-20">
                                      報名記錄
                                    </CardTitle>
                                    <CardTitle className="font-size-20">
                                      總數: {count}
                                    </CardTitle>
                                  </CardHeader>

                                  <CardBody>
                                    <Row>
                                      <Col lg={3} sm={12}>
                                        <AutoCompleteSelect
                                          name="userId"
                                          label="會員"
                                          placeholder="會員 ( 會員名稱 / 電話 )"
                                          options={userList}
                                          onMenuScrollToBottom={
                                            userScrollToBottom
                                          }
                                          inputValue={userText}
                                          onInputChange={text =>
                                            setUserText(text)
                                          }
                                          getOneData={
                                            updateUserListWithItemCall
                                          }
                                        />
                                      </Col>
                                      <Col lg={3} sm={12}>
                                        <SelectFilter
                                          label="狀態"
                                          placeholder="狀態"
                                          name="status"
                                          options={
                                            EVENT_APPLICATION_STATUS_OPTIONS
                                          }
                                          onChange={e =>
                                            setEventApplicationMap({})
                                          }
                                          isClearable
                                        />
                                      </Col>

                                      <Col lg={3} sm={12}>
                                        <DatePickerFilter
                                          label="創建日期範圍"
                                          name="createdAtFrom"
                                          name2="createdAtTo"
                                          placeholder="創建日期範圍"
                                          mode="range"
                                          isClearable
                                        />
                                      </Col>
                                      <Col
                                        lg={3}
                                        sm={12}
                                        className="d-flex flex-row-reverse align-items-end"
                                      >
                                        <div>
                                          <Button
                                            className="ms-1 mb-2"
                                            color="success"
                                            onClick={() =>
                                              setBatchApproveEventApplicationVisible(
                                                true,
                                              )
                                            }
                                            disabled={
                                              Object.keys(eventApplicationMap)
                                                .length === 0 || loading
                                            }
                                          >
                                            批准
                                          </Button>
                                          <Button
                                            className="ms-1 me-1 mb-2"
                                            color="danger"
                                            onClick={() =>
                                              setBatchRejectEventApplicationVisible(
                                                true,
                                              )
                                            }
                                            disabled={
                                              Object.keys(eventApplicationMap)
                                                .length === 0 || loading
                                            }
                                          >
                                            拒絕
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <Table
                                            columns={EventApplicationListColumns(
                                              approveButtonOnClick,
                                              cancelButtonOnClick,
                                              loading,
                                            )}
                                            isAllowSorting={false}
                                            keyField="eventApplicationId"
                                            data={eventApplicationList || []}
                                            checkableConfig={{
                                              isCheckBoxShow: (
                                                item: EventApplication,
                                              ) => item.status === 'Pending',
                                              onCheck: itemOnCheck,
                                              selected: eventApplicationMap,
                                              checkAll: checkAllOnPress,
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-3">
                                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                        <Pagination
                                          current={page}
                                          count={count}
                                          pageSize={SUBPAGE_PAGE_SIZE}
                                          disableAutoScrollToTop={true}
                                          onChange={(page: number) => {
                                            setPage(page);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Dialog
          visible={cancelEventApplicationVisible}
          title="拒絕待確認名額"
          onClose={() => setCancelEventApplicationVisible(false)}
          loading={loading}
          onConfirm={cancelConfirm}
        >
          <p>{`確定要拒絕待確認名額（編號: ${clickedEventApplicationId}）?`}</p>
        </Dialog>
        <Dialog
          visible={approveEventApplicationVisible}
          title={`批准待確認名額`}
          onClose={() => setApproveEventApplicationVisible(false)}
          loading={loading}
          onConfirm={approveConfirm}
        >
          <p>{`確定要批准待確認名額 (編號: ${clickedEventApplicationId})`}</p>
        </Dialog>
        <Dialog
          visible={batchApproveEventApplicationVisible}
          title={`批准已選待確認名額數量: ${
            Object.keys(eventApplicationMap).length
          }`}
          onClose={() => setBatchApproveEventApplicationVisible(false)}
          loading={loading}
          onConfirm={batchApproveConfirm}
        >
          <p>{`確定要批准待所有已選確認名額?`}</p>
        </Dialog>
        <Dialog
          visible={batchRejectEventApplicationVisible}
          title={`拒絕已選待確認名額數量: ${
            Object.keys(eventApplicationMap).length
          }`}
          onClose={() => setBatchRejectEventApplicationVisible(false)}
          loading={loading}
          onConfirm={batchRejectConfirm}
        >
          <p>{`確定要拒絕所有已選待確認名額?`}</p>
        </Dialog>
        <Dialog
          visible={approveEventVisible}
          title={`批准中心活動: #${eventId}`}
          onClose={() => setApproveEventVisible(false)}
          loading={loading}
          onConfirm={() => changeEventStatus(EventStatus.Approved)}
        >
          <p>{`確定要批准中心活動: #${eventId}?`}</p>
        </Dialog>
        <Dialog
          visible={rejectEventVisible}
          title={`拒絕中心活動: #${eventId}`}
          onClose={() => setRejectEventVisible(false)}
          loading={loading}
          onConfirm={() => changeEventStatus(EventStatus.Rejected)}
        >
          <p>{`確定要拒絕中心活動: #${eventId}?`}</p>
        </Dialog>
      </div>
    </>
  );
};
