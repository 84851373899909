import { TableCount } from 'app/components/Common';
import Breadcrumbs from 'app/components/Common/Breadcrumb';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import Dialog from 'app/components/Modal/Modal';
import { Pagination, Table } from 'app/components/Table';
import { API_URL, APP_NAME } from 'app/config';
import { JOB_APPLICATION_STATUS_OPTIONS } from 'app/constants';
import { useAutoComplete, useTableComponentForListPage } from 'app/hooks';
import {
  AdminRoleEnum,
  CompanyResonse,
  Job,
  JobApplication,
  User,
} from 'app/models';
import {
  getJobApplicationsForCompanyAdmin,
  getJobForCompanyAdmin,
} from 'app/services/AdminServices';
import {
  getCompanyListData,
  GetCompanyListParams,
  getOneCompany,
} from 'app/services/CompanyServices';
import {
  getJobApplicationsList,
  GetJobApplicationsListParams,
  getJobListData,
  GetJobListParams,
  getOneJob,
  updateOneJobApplication,
} from 'app/services/JobService';
import {
  getOneUser,
  getUserList,
  GetUserListParams,
} from 'app/services/UserService';
import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { RootState } from 'store';
import JobApplicationListColumns from './JobApplicationListColumns';
import JobApplicationListColumnsForAdmin from './JobApplicationListColumnsForAdmin';

export const JobApplicationListPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { auth } = useSelector((rootState: RootState) => rootState);
  const currentRole = auth.Auth ? auth?.Auth?.role : '';

  const [loading, setLoading] = useState<boolean>(false);
  const [changeStatusVisible, setChangeStatusVisible] = useState<boolean>(
    false,
  );
  const [clickedJobApplicationId, setClickedJobApplicationId] = useState<
    number
  >(0);
  const [clickedJobStatus, setClickedJobStatus] = useState<string>('');

  const [, setURL] = useState<string>(
    `${API_URL}/v1/events/applications/report?`,
  );
  const {
    dataList: jobApplicationList,
    count,
    page,
  } = useTableComponentForListPage<
    GetJobApplicationsListParams,
    JobApplication
  >({
    call:
      currentRole === AdminRoleEnum.超級管理員 ||
      currentRole === AdminRoleEnum.職員
        ? getJobApplicationsList
        : getJobApplicationsForCompanyAdmin,
    filterVariableArray: [
      'userId',
      'jobId',
      'companyId',
      'status',
      'createdAtFrom',
      'createdAtTo',
      'desc',
      'asc',
    ],
  });
  const {
    searchText: userText,
    setSearchText: setUserText,
    dataList: userList,
    onMenuScrollToBottom: userScrollToBottom,
    updateDataListWithItemCall: updateUserListWithItemCall,
  } = useAutoComplete<GetUserListParams, User>({
    getItemCall: getOneUser,
    getListCall: getUserList,
    labelRender: (item: User) => ({
      label: `#${item.userId} | ${item.displayName} | ${item.phoneNumber}`,
      value: item.userId.toString(),
    }),
    keyExtracter: (item: User) => item.userId,
    searchTextFieldName: 'q',
    preventFirstLoad: currentRole === AdminRoleEnum.公司或機構管理員,
  });

  const {
    searchText: jobText,
    setSearchText: setJobText,
    dataList: jobList,
    onMenuScrollToBottom: onJobMenuScrollToBottom,
    updateDataListWithItemCall: updateJobListWithItemCall,
  } = useAutoComplete<GetJobListParams, Job>({
    getItemCall: getOneJob,
    getListCall:
      currentRole === AdminRoleEnum.超級管理員 ||
      currentRole === AdminRoleEnum.職員
        ? getJobListData
        : getJobForCompanyAdmin,
    labelRender: (item: Job) => ({
      label: `#${item.jobId} | ${item.title} `,
      value: item.jobId.toString(),
    }),
    keyExtracter: (item: Job) => item.jobId,
    searchTextFieldName: 'title',
  });

  const {
    searchText: companyText,
    setSearchText: setCompanyText,
    dataList: companyList,
    onMenuScrollToBottom: onEventMenuScrollToBottom,
    updateDataListWithItemCall: updateCompanyListWithItemCall,
  } = useAutoComplete<GetCompanyListParams, CompanyResonse>({
    getItemCall: getOneCompany,
    getListCall: getCompanyListData,
    labelRender: (item: CompanyResonse) => ({
      label: `#${item.companyId} | ${item.name}`,
      value: item.companyId.toString(),
    }),
    keyExtracter: (item: CompanyResonse) => item.companyId,
    searchTextFieldName: 'name',
    preventFirstLoad: currentRole === AdminRoleEnum.公司或機構管理員,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');
    const eventId = params.get('eventId');
    const status = params.get('status');
    const createdAtFrom = params.get('createdAtFrom');
    const createdAtTo = params.get('createdAtTo');
    setURL(
      `${API_URL}/v1/events/applications/report?${
        userId ? `userId=${userId}&` : ''
      }${eventId ? `eventId=${eventId}&` : ''}${
        status ? `status=${status}&` : ''
      }${createdAtFrom ? `createdAtFrom=${createdAtFrom}&` : ''}${
        createdAtTo ? `createdAtTo=${createdAtTo}&` : ''
      }`,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const changeStatusButtonOnClick = async (
    applicationId: string,
    status: string,
  ) => {
    setClickedJobApplicationId(parseInt(applicationId));
    setClickedJobStatus(status);
    setChangeStatusVisible(true);
  };

  const changeStatusConfirm = async () => {
    setLoading(true);
    try {
      if (clickedJobApplicationId) {
        await updateOneJobApplication(clickedJobApplicationId, {
          status: clickedJobStatus,
        });
        toast.success('成功確更改申請記錄狀態');
        setLoading(false);
        setChangeStatusVisible(false);
        await new Promise(resolve => setTimeout(resolve, 1200));
        history.go(0);
      }
    } catch (err) {
      toast.error('更改申請記錄狀態失敗，請重試。');
      setLoading(false);
      setChangeStatusVisible(false);
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>申請記錄列表 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="申請記錄" breadcrumbItem="申請記錄列表" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col lg={9}>
                      <Row>
                        {currentRole === AdminRoleEnum.超級管理員 ||
                        currentRole === AdminRoleEnum.職員 ? (
                          <>
                            <Col lg={3}>
                              <AutoCompleteSelect
                                name="companyId"
                                label="公司"
                                placeholder="公司"
                                inputValue={companyText}
                                onInputChange={setCompanyText}
                                options={companyList}
                                onMenuScrollToBottom={onEventMenuScrollToBottom}
                                getOneData={updateCompanyListWithItemCall}
                              />
                            </Col>

                            <Col lg={3} sm={12}>
                              <AutoCompleteSelect
                                name="userId"
                                label="會員"
                                placeholder="會員 ( 會員名稱 / 電話 )"
                                options={userList}
                                inputValue={userText}
                                onMenuScrollToBottom={userScrollToBottom}
                                onInputChange={text => setUserText(text)}
                                getOneData={updateUserListWithItemCall}
                              />
                            </Col>
                          </>
                        ) : null}
                        <Col lg={3} sm={12}>
                          <AutoCompleteSelect
                            name="jobId"
                            label="工作機會"
                            placeholder="工作機會"
                            inputValue={jobText}
                            onInputChange={setJobText}
                            options={jobList}
                            onMenuScrollToBottom={onJobMenuScrollToBottom}
                            getOneData={updateJobListWithItemCall}
                          />
                        </Col>
                        <Col lg={3} sm={12}>
                          <SelectFilter
                            label="狀態"
                            placeholder="狀態"
                            name="status"
                            options={JOB_APPLICATION_STATUS_OPTIONS}
                            isClearable
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} sm={12}>
                      <DatePickerFilter
                        label="創建日期範圍"
                        name="createdAtFrom"
                        name2="createdAtTo"
                        placeholder="創建日期範圍"
                        mode="range"
                        isClearable
                      />
                    </Col>
                    {/* <Col
                      lg={3}
                      sm={12}
                      className="d-flex flex-row-reverse align-items-end"
                    >
                      <div>
                        <Link className=" btn btn-info mb-2 " to={url}>
                          匯出以下列表
                        </Link>
                      </div>
                    </Col> */}
                  </Row>
                  <Row className="mb-2">
                    <Col
                      sm={12}
                      className="d-flex align-self-end justify-content-end"
                    >
                      <TableCount count={count} title="申請記錄總數" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <Table
                          columns={
                            currentRole === AdminRoleEnum.公司或機構管理員
                              ? JobApplicationListColumns(
                                  changeStatusButtonOnClick,
                                  currentRole,
                                  loading,
                                )
                              : JobApplicationListColumnsForAdmin()
                          }
                          keyField="jobApplicationId"
                          data={jobApplicationList || []}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                      <Pagination
                        count={count}
                        current={page}
                        onChange={page => {
                          const params = new URLSearchParams(location.search);
                          params.set('page', page.toString());
                          history.push({ search: params.toString() });
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Dialog
          visible={changeStatusVisible}
          title="更改狀態"
          onClose={() => setChangeStatusVisible(false)}
          loading={loading}
          onConfirm={changeStatusConfirm}
        >
          <p>{`確定要更改申請記錄狀態（編號: ${clickedJobApplicationId}）?`}</p>
        </Dialog>
      </div>
    </>
  );
};
