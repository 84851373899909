import { EventApplicationStatusMap } from 'app/constants';
import { EventApplication, ListColumn } from 'app/models/';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';

const EventApplicationListColumns: (
  approvefunction,
  cancelFunc,
  loading,
) => ListColumn<EventApplication>[] = (
  approvefunction,
  cancelFunc,
  loading,
) => [
  {
    text: '編號',
    dataField: 'eventApplicationId',
    canSort: true,
    formatter: (eventApplication: EventApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">
            #{eventApplication.eventApplicationId}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: ' 會員編號 | 會員 | 聯絡電話',
    dataField: 'userId',
    canSort: false,
    formatter: (eventApplication: EventApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <Link to={`/users/detail/${eventApplication.userId}`}>
            <div className="text-dark">
              {eventApplication.name
                ? `#${eventApplication.userId} | ${eventApplication.name}`
                : `#${eventApplication.userId} | ${eventApplication.User.displayName}`}
            </div>
            <div className="text-dark mt-1 font-size-12">
              聯絡電話:{' '}
              {eventApplication.phone
                ? eventApplication.phone
                : eventApplication.User.phoneNumber
                ? eventApplication.User.phoneNumber
                : '-'}
            </div>
          </Link>
        </h5>
      </>
    ),
  },
  {
    text: '活動',
    dataField: 'eventId',
    canSort: true,
    formatter: (eventApplication: EventApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-column">
          <div className="text-dark">
            <Link
              className="text-dark"
              to={`/events/items/detail/${eventApplication.eventId}`}
            >
              {eventApplication.Event
                ? `#${eventApplication.eventId} | ${eventApplication.Event.title}`
                : '-'}
            </Link>
          </div>
        </h5>
      </>
    ),
  },

  {
    text: '狀態',
    dataField: 'status',
    canSort: true,
    formatter: (eventApplication: EventApplication) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">
            {eventApplication?.status ? (
              <Badge
                pill
                className={`badge-soft-${
                  EventApplicationStatusMap.get(eventApplication.status)
                    ?.colorType
                } ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
              >
                {EventApplicationStatusMap.get(eventApplication.status)?.label}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '確認名額',
    dataField: 'menu',
    formatter: (eventApplication: EventApplication) => (
      <h5 className="font-size-14 mb-1">
        <div className="text-dark d-flex flex-row">
          <>
            <Button
              className="ms-1 py-1 font-size-12"
              color="success"
              onClick={() =>
                approvefunction(eventApplication.eventApplicationId)
              }
              disabled={loading || eventApplication.status !== 'Pending'}
            >
              批准
            </Button>
            <Button
              className="ms-1 py-1 font-size-12"
              color="danger"
              onClick={() => cancelFunc(eventApplication.eventApplicationId)}
              disabled={loading || eventApplication.status !== 'Pending'}
            >
              拒絕
            </Button>
          </>
        </div>
      </h5>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    canSort: true,
    formatter: (eventApplication: EventApplication) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(eventApplication.createdAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '更新時間',
    dataField: 'updatedAt',
    canSort: true,
    formatter: (eventApplication: EventApplication) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(eventApplication.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (eventApplication: EventApplication) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          {eventApplication.status !== '確認名額中' ? (
            <Link
              to={`/events/applications/edit/${eventApplication.eventApplicationId}`}
              id={'edit' + eventApplication.eventApplicationId}
            >
              <i className="bx bx-edit-alt" />
              <UncontrolledTooltip
                placement="top"
                target={'edit' + eventApplication.eventApplicationId}
              >
                編輯中心活動
              </UncontrolledTooltip>
            </Link>
          ) : (
            '-'
          )}
        </li>
      </ul>
    ),
  },
];

export default EventApplicationListColumns;
