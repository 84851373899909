import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type InputType = 'checkbox' | 'radio';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  type?: InputType;
  disabled?: boolean;
  className?: string;
}

const RadioCheckboxField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const { label, type, horizontal, className, disabled } = props;
  return (
    <>
      <FormGroup
        check
        className={clsx(
          horizontal && 'd-inline-block mb-1 mt-1 ms-1 me-1',
          className && className,
        )}
      >
        <Label
          className={clsx(
            meta.touched && meta.error && 'text-danger',
            className,
          )}
          check
        >
          <Input
            type={type}
            {...field}
            style={{ height: '1.1em', width: '1.1em' }}
            disabled={disabled}
          />

          {label}
        </Label>
        {meta.touched && meta.error ? (
          <div
            className={clsx(meta.touched && meta.error && 'text-danger')}
            style={{ marginTop: type === 'checkbox' ? '-15px' : '-2px' }}
          >
            *{meta.error}
          </div>
        ) : null}
      </FormGroup>
    </>
  );
};

export default RadioCheckboxField;
