import { PAGE_SIZE } from 'app/config';
import { ListColumn } from 'app/models';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';

export interface CheckableConfig {
  noWrap?: boolean;
  onCheck: (item: any) => void;
  isCheckBoxShow?: (item: any) => boolean;
  selected: Record<string | number, any>;
  checkAll: () => void;
}

interface Props {
  isAllowSorting?: boolean;
  columns: ListColumn<any>[];
  data: any[];
  keyField: string;
  onClick?: (id: string) => void;
  checkableConfig?: CheckableConfig;
}

const CustomTable = ({
  columns,
  data,
  keyField,
  onClick,
  checkableConfig,
  isAllowSorting = true,
}: Props) => {
  const { selected, checkAll, isCheckBoxShow, onCheck, noWrap } =
    checkableConfig ?? {};
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const desc = query.get('desc');
  const asc = query.get('asc');
  const [selectedField, setSelectedField] = useState<string>(
    desc ? desc : asc ? asc : '',
  );
  const [orderType, setOrderType] = useState<number>(desc ? 1 : asc ? 2 : 0);
  const history = useHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortOrderTypeArray = [undefined, 'desc', 'asc'];

  const columnOnClick = (field: string) => {
    if (field === selectedField) {
      setOrderType((orderType + 1) % 3);
    } else {
      setOrderType(1);
    }
    setSelectedField(field);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (orderType && sortOrderTypeArray[orderType]) {
      params.set(sortOrderTypeArray[orderType] as string, selectedField);
      params.delete(sortOrderTypeArray[(orderType + 3 - 1) % 3] as string);
    } else {
      params.delete(sortOrderTypeArray[(orderType + 3 - 1) % 3] as string);
    }
    history.replace({ search: params.toString() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField, orderType]);

  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          {selected && checkAll && isCheckBoxShow ? (
            <th className="align-middle" onClick={() => checkAll()}>
              {isCheckBoxShow && data.filter(isCheckBoxShow).length !== 0 ? (
                <>
                  {((Object.keys(selected).length === PAGE_SIZE ||
                    Object.keys(selected).length === data.length) &&
                    Object.keys(selected).length > 0) ||
                  (isCheckBoxShow &&
                    Object.keys(selected).length ===
                      data.filter(isCheckBoxShow).length) ? (
                    <i className="bx bx-check-square check-icon active fs-5 " />
                  ) : (
                    <i className="bx bx-square check-icon fs-5 " />
                  )}
                </>
              ) : null}
            </th>
          ) : null}
          {columns.map((column, index) => {
            const canSort =
              column.canSort &&
              isAllowSorting &&
              column.text !== '' &&
              column.dataField !== '' &&
              column.dataField !== 'img' &&
              column.dataField !== 'menu';
            return (
              <th
                key={`${column.text}_${index}`}
                onClick={() => {
                  if (canSort) columnOnClick(column.dataField as string);
                }}
                className={`${
                  canSort
                    ? selectedField === column.dataField && orderType !== 0
                      ? 'text-primary'
                      : ''
                    : ''
                } `}
                role={canSort ? 'button' : ''}
                style={{ userSelect: 'none' }}
              >
                {column.text}
                {canSort ? (
                  <>
                    {selectedField === column.dataField ? (
                      <>
                        {orderType === 1 ? (
                          <i
                            className="bx bxs-down-arrow position-relative"
                            style={{ fontSize: 6.5, bottom: 2, left: 2 }}
                          />
                        ) : (
                          <>
                            {orderType === 2 ? (
                              <i
                                className="bx bxs-up-arrow position-relative"
                                style={{ fontSize: 6.5, bottom: 6, left: 2 }}
                              />
                            ) : (
                              <i
                                className="bx bxs-sort-alt position-relative"
                                style={{ fontSize: 10, bottom: 2, left: 1 }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <i
                        className="bx bxs-sort-alt position-relative"
                        style={{ fontSize: 10, bottom: 2, left: 1 }}
                      />
                    )}
                  </>
                ) : null}
              </th>
            );
          })}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                key={`${item[keyField]}_${index}`}
                onClick={() => {
                  if (onClick) {
                    onClick(item[keyField]);
                  }
                }}
              >
                {onCheck && selected ? (
                  <td
                    style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                    onClick={() => {
                      if (!isCheckBoxShow || isCheckBoxShow(item))
                        onCheck(item);
                    }}
                  >
                    {!isCheckBoxShow || isCheckBoxShow(item) ? (
                      <>
                        {selected[item[keyField]] ? (
                          <i className="bx bx-check-square check-icon active fs-5" />
                        ) : (
                          <i className="bx bx-square check-icon fs-5" />
                        )}
                      </>
                    ) : null}
                  </td>
                ) : null}
                {columns.map((column, subIndex) => {
                  if (column.formatter) {
                    return (
                      <td
                        key={`${item[keyField]}_${index}_${subIndex}`}
                        style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                      >
                        {column.formatter(item)}
                      </td>
                    );
                  } else {
                    return (
                      <td key={`${item[keyField]}_${index}_${subIndex}`}>
                        {item[column.dataField]}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td
              colSpan={columns.length}
              className="text-center border-0 text-size-16 fw-bold"
            >
              沒有資料
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};

export default CustomTable;
