import clsx from 'clsx';
import 'flatpickr/dist/themes/material_blue.css';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { InputGroup, Label } from 'reactstrap';

interface OtherProps {
  label: string;
  placeholder?: string;
  minDate?: Date | Date[] | null;
  onChange?: (value) => void;
}

const DateField = (props: OtherProps & FieldHookConfig<Date[] | null>) => {
  const [field, meta, helpers] = useField(props);
  const { label, placeholder, minDate, onChange } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <InputGroup>
        <Flatpickr
          className="form-control d-block bg-white"
          placeholder={placeholder || '日期'}
          options={{
            altInput: true,
            altFormat: 'd-m-Y',
            dateFormat: 'd-m-Y',
            minDate: minDate ? minDate[0] : null,
          }}
          value={field.value}
          onChange={date => {
            if (onChange) {
              onChange(date);
            } else {
              if (date[0]) {
                helpers.setValue(date);
              } else {
                helpers.setValue(null);
              }
            }
          }}
        />
      </InputGroup>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default DateField;
